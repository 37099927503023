import { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import { CheckboxGroup } from "./CheckboxGroup";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "./Dialog";

export function CheckboxGroupDialog(props) {
  const { onClose, open, groups: groupsProps, value: valueProp, title, onCancel, onCheckboxGroupChange, ...other } = props;
  const radioGroupRef = useRef(null);

  const [groups, setGroups] = useState(groupsProps)
  const [value, setValue] = useState(valueProp)

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
      setGroups(groupsProps)
    }
  }, [valueProp, groupsProps, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCheckboxGroupChange = (groupName, checkboxes) => {
    let finalValue = value

    checkboxes.forEach(checkbox => {
        const { value: checkboxValue, checked } = checkbox
        if (checked)
          !finalValue.includes(checkboxValue) && finalValue.push(checkboxValue)
        else
          finalValue = finalValue.filter(item => item !== checkboxValue)
    });

    const finalGroups = groups.map(group => {
      if(group.name === groupName) group.options = checkboxes
      return group
    })

    setGroups(finalGroups)
    setValue(finalValue)
  }

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {groups.map(group => {
          return <CheckboxGroup key={group.name} groupLabel={group.name} checkboxes={group.options} onCheckboxGroupChange={handleCheckboxGroupChange} />
        })}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">Cancelar</Button>
        <Button onClick={handleOk} color="primary">Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
