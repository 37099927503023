import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "../../core";

export const TextEdit = ({ content: oldContent, handleClose, handleSave }) => {

  const {value = {}} = oldContent

  const [content, setContent] = useState(value);
  const { title, subtitle, text } = content;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Edição do Texto</DialogTitle>
      <DialogContent>
        <TextField
          label="Título"
          value={title}
          onChange={handleChange}
          fullWidth
          name="title"
        />
        <TextField
          label="Subtítulo"
          value={subtitle}
          onChange={handleChange}
          fullWidth
          style={{ marginTop: 10 }}
          name="subtitle"
        />
        <TextField
          label="Text"
          minRows={3}
          multiline
          onChange={handleChange}
          value={text}
          style={{ marginTop: 10 }}
          fullWidth
          name="text"
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave(content);
            handleClose();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
