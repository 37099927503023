import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api'
import { Paper, Button } from '../../core'
import { SecctionContainer, SeccionHeader } from '../../patterns'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function TranslationsList() {

    const [translations, setTranslations] = useState([]);

    useEffect(() => {
        api.get('/admin/translations?fields=pt').then(res => {
            setTranslations(res.data.translations)
        }).catch(err => {
            console.log(err)
        })
    }, []);

    const history = useHistory();

    const handleNewTranslation = useCallback(() => history.push(`/app/translations/edit/new`), [history]);
    const handleTranslationDetails = useCallback((id) => history.push(`/app/translations/${id}`), [history]);

    return (
        <SecctionContainer>
            <SeccionHeader title='Traduções' newButtonAction={handleNewTranslation} newButton />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Texto em Português</TableCell>
                            <TableCell width={0} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {translations.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell flex style={{ padding: 5 }} align="left">{row.pt}</TableCell>
                                <TableCell width={0} align="right">
                                    <Button onClick={() => handleTranslationDetails(row.id)} size='small' variant='contained' color='primary'>Visualizar</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SecctionContainer>
    )
}
