import { withStyles } from '@material-ui/core/styles';
import { Switch as MUISwitch } from '@material-ui/core'

/* Props
    theme (String) default 'pt' | ['pt', 'es'] - Change Object Theme
*/

const CustomSwitch = withStyles((theme) => ({
    switchBase: {
    },
    checked: {},
    track: {},
}))(MUISwitch);

export const Switch = (props) => <CustomSwitch {...props} />

// LanguageSwitch
export const LanguageSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primaryES.main,
        }
    },
    checked: {},
    track: {},
}))(MUISwitch);

