import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField, Div, IconButton, Radio
} from "../../core";
import { AddIcon, DeleteIcon } from '../../core/Icons'
import { theme } from '../../../theme'
import ListIcon from '@material-ui/icons/List';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

export const ListEdit = ({ content: oldContent, handleClose, handleSave }) => {

  const { value = {} } = oldContent

  const [content, setContent] = useState(value);
  const { items = [''] } = content;
  const { type = 'list' } = content

  const handleChange = ({ target, index }) => {
    const { name, value } = target;

    let newItems = items
    newItems[index] = value

    setContent({
      ...content,
      items: newItems,
    });
  };

  const handleAddItem = () => {

    let newItems = items
    newItems.push('')
    setContent({
      ...content,
      items: newItems
    })
  }

  const handleRemoveItem = (index) => {

    let newItems = items
    newItems.splice(index, 1);

    setContent({
      ...content,
      items: newItems
    })
  }

  const handleListType = (type) => {
    setContent({
        ...content,
        type
      }
    )
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>
        <Div style={{ display: 'flex', flexDirection: 'row' }}>
          <Div style={{ textAlign: 'left', justifyContent: 'flex-start' }}>Edição da Lista</Div>
          <IconButton style={{ marginLeft: 10 }} onClick={handleAddItem} size="small"><AddIcon style={{ color: theme.palette.primary.main }} /></IconButton>
        </Div>
        <Div>
          <Button variant='outlined' onClick={()=>handleListType('list')} style={{ backgroundColor: content.type=='list' ? 'gray' : '#FFF'}}><ListIcon /></Button>
          <Button onClick={()=>handleListType('number')} variant='outlined' style={{ backgroundColor: content.type=='number' ? 'gray' : '#FFF'}}><FormatListNumberedIcon /></Button>
        </Div>
      </DialogTitle>
      <DialogContent>
        {items.map((item, index) => (
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
            <TextField
              label="Item"
              value={item}
              onChange={({ target }) => handleChange({ target, index })}
              fullWidth
              name="item"
            />
            <IconButton style={{ marginLeft: 10 }} onClick={() => handleRemoveItem(index)} size="small"><DeleteIcon style={{ color: 'lightgray' }} /></IconButton>
          </div>
        ))}



      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave(content);
            handleClose();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
