import {theme} from '../../../theme'
import {Div, Text} from '../../core'


export const ContentQuestion = ({content, index}) => {
    const { value = {} } = content
    const { question, answer, multipleChoice} = value
    return (
        <div style={styles.container}>
            {question && <Text style={styles.question}>{question}</Text>}
            {!multipleChoice && answer && <Text style={styles.answer}>{answer}</Text>}
            {multipleChoice && answer && <Div style={styles.options}>{answer.map(option => <Text>{option.answer}</Text>)}</Div>}
            {!question && !answer && <Div style={styles.placeholder}><Text>Insira aqui a pergunta</Text></Div>}
        </div>
    )
}

const styles = {
    container: {
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    question: {
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20,
        marginBottom: 10
    },
    answer: {
        marginTop: 20,
        fontSize: 14,
        fontWeight: '400',
        width: 340,
        wordWrap: 'break-word'
    },
    options: {
        display: 'flex',
        flexDirection: 'column',
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}
