import { TablePagination } from '../../core'

export const LessonPaginator = ({ onPerPageChange, pagination, onPageChange }) => (
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={pagination.count}
        rowsPerPage={pagination.per_page}
        page={pagination.page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPerPageChange}
    />
)