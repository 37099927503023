import { useState, useContext, useEffect } from "react";

import api from "../../../services/api";
import { Button, Paper, TextField, Text } from "../../core";
import { AuthContext } from "../../../contexts/AuthContext";
import { AlertDialog } from "../../patterns";
import { AppContext } from "../../../contexts/AppContext";

export default function RequestNewPasswordForm() {
  const { isAuthenticated, history } = useContext(AuthContext);
  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });
  const [email, setEmail] = useState();
  const [state, setState] = useState({ instructionsSent: false });
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/app");
    }
  }, [history, isAuthenticated]);

  const handleChange = (event) => {
    const { value } = event.target;

    setEmail(value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    api
      .post("/api/edge/forgot-password", { forgotPassword: { email } })
      .then((res) => {
        state.instructionsSent = true;
        setState({ ...state });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ errors: err.response.data.errors, show: true });
      });
  };

  const redirectToLogin = async (event) => {
    event.preventDefault();
    history.push("/");
  };

  return (
    <div style={styles.container}>
      <AlertDialog
        open={alert.show}
        handleClose={() => setAlert({ ...alert, show: false })}
        errors={alert.errors}
      />
      <form onSubmit={submitForm}>
        <Paper style={styles.loginBox} elevation={6}>
          <div style={styles.logoContainer}>
            <img
              src="../logotipo-fundamentos.png"
              style={styles.logo}
              alt="Fundamentos"
            />
          </div>

          {state.instructionsSent ? (
            <>
              <Text>
                Instruções enviadas para {email}! Verifique sua caixa de
                entrada.
              </Text>
              <Button
                style={styles.loginButton}
                variant="contained"
                color="primary"
                size="medium"
                onClick={redirectToLogin}
              >
                Voltar para login
              </Button>
            </>
          ) : (
            <>
              <Text>
                Informe seu email e enviaremos instruções sobre os próximos
                passos para reiniciar sua senha:
              </Text>
              <TextField
                variant="outlined"
                size="small"
                label="E-mail"
                style={styles.loginField}
                value={email}
                name="email"
                onChange={handleChange}
                type="email"
                required
              />
              <Button
                style={styles.loginButton}
                variant="contained"
                color="primary"
                size="medium"
                type="Submit"
              >
                Enviar instruções
              </Button>
              <div style={styles.buttonsContainer}>
                <Button
                  color="primary"
                  style={{ color: "#000" }}
                  size="small"
                  onClick={redirectToLogin}
                >
                  Voltar para login
                </Button>
              </div>
            </>
          )}
        </Paper>
      </form>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: 'url("Background2.png")',
    backgroundSize: "cover",
  },
  loginBox: {
    padding: 20,
    margin: 10,
    maxWidth: 300,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: '#fece03'
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 25,
  },
  logo: {
    maxWidth: 150,
  },
  loginField: {
    marginTop: 10,
  },
  loginButton: {
    width: "100%",
    height: 35,
    marginTop: 20,
  },
  buttonsContainer: {
    display: "flex",
    // flexDirection: 'row',
    justifyContent: "space-between",
    marginTop: 10,
  },
};
