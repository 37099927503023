import { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { AppContext } from "../../../contexts/AppContext";
import {
  Paper,
  Avatar,
  Button,
  Select,
  Spacer,
  TextField,
  Divider,
  MenuItem,
  Div,
} from "../../core";
import { SecctionContainer, SeccionHeader, AlertDialog } from "../../patterns";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { LessonPaginator } from "../../patterns/lesson/LessonPaginator";

export default function UsersList() {
  const { setLoading } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    count: 0,
    sort: "desc",
    sortField: "createdAt",
  });

  useEffect(() => {
    loadUsers();
  }, [pagination.page, pagination.per_page, pagination.sort]);

  const loadUsers = () => {
    setLoading(true);

    api
      .get(
        `/admin/users?fields=name,email&page=${pagination.page}&per_page=${pagination.per_page}&sort=${pagination.sortField}:${pagination.sort}&q=name:like:${query}`
      )
      .then((res) => {
        setPagination({ ...pagination, count: res.data.pagination.count });
        setLoading(false);
        setUsers(res.data.users);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ errors: err.response.data.errors, show: true });
      });
  };

  const onPerPageChange = ({ target }) => {
    setPagination({ ...pagination, per_page: target.value, page: 1 });
    console.log(pagination);
  };

  const onPageChange = (event, newPage) => {
    setPagination({ ...pagination, page: newPage + 1 });
  };

  const handleSortChange = ({ target }) => {
    setPagination({
      ...pagination,
      sort: target.value,
    });
    console.log(pagination);
  };

  const handleQueryChange = ({ target }) => {
    const { value } = target;
    setQuery(value);
  };

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });
  //=================================================//

  // useEffect(() => {
  //     setLoading(true);
  //     api.get('/admin/users?fields=name,email').then(res => {
  //         setLoading(false);
  //         setUsers(res.data.users)
  //     }).catch(err => {
  //         setLoading(false);
  //         console.log(err)
  //     })
  // }, []);

  const history = useHistory();

  const handleEdit = useCallback(
    (id) => history.push(`/app/user/${id}`),
    [history]
  );
  const handleNewUser = useCallback(
    () => history.push(`/app/user/edit/new`),
    [history]
  );

  return (
    <SecctionContainer>
      <SeccionHeader
        title="Usuários"
        newButtonAction={handleNewUser}
        newButton
      />
      <AlertDialog
        open={alert.show}
        handleClose={() => setAlert({ ...alert, show: false })}
        errors={alert.errors}
      />
      <TableContainer component={Paper}>
        <Div>
          <TextField
            label="Busca"
            variant="outlined"
            refs="queryUser"
            value={query}
            fullWidth
            autoFocus
            name="queryUser"
            placeholder="Digite o termo que deseja buscar"
            onChange={handleQueryChange}
            onKeyPress={({ key }) => {
              if (key === "Enter") loadUsers();
            }}
            style={{ marginTop: 20, marginLeft: 20 }}
          />
          <Spacer />
          <Select
            id="sort"
            value={pagination.sort}
            handleChange={handleSortChange}
            label="Ordem"
            name="sort"
            style={{ marginLeft: 20, marginRight: 20, maxWidth: 200 }}
          >
            <MenuItem value={"asc"}>Data de Criação (asc)</MenuItem>
            <MenuItem value={"desc"}>Data de Criação (desc)</MenuItem>
          </Select>
        </Div>
        <LessonPaginator
          pagination={pagination}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
        <Divider />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={0} align="left"></TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">E-mail</TableCell>
              <TableCell width={0} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ padding: 10 }} align="left">
                  <Avatar
                    alt={row.name}
                    src={`${row.avatar}`}
                    style={{ margin: 0 }}
                  />
                </TableCell>
                <TableCell flex style={{ padding: 5 }} align="left">
                  {row.name}
                </TableCell>
                <TableCell flex style={{ padding: 5 }} align="left">
                  {row.email}
                </TableCell>
                <TableCell width={0} align="right">
                  <Button
                    onClick={() => handleEdit(row.id)}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Visualizar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SecctionContainer>
  );
}
