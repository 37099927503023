import { useCallback, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { IconButton, Menu, MenuItem, Image } from "../core";

export const UserMenu = () => {
  const { user } = useContext(AuthContext);
  const { avatar, id } = user;

  const history = useHistory();

  const handlePasswordChange = useCallback(
    () => history.push(`/app/user/changepassword/${id}`),
    [history]
  );

  const handleProfileChange = useCallback(() => 
    history.push(`/app/user/profile/${id}`), [history]
  );

  const { signOut } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Image
          src={avatar}
          width={35}
          height={35}
          style={{ borderRadius: "50%" }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={handleProfileChange}>Meus dados</MenuItem>
        <MenuItem onClick={handlePasswordChange}>Alterar senha</MenuItem>
        <MenuItem onClick={signOut}>Sair</MenuItem>
      </Menu>
    </>
  );
};
