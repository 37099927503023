import { useContext, useEffect, useState } from "react";
import api from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";
import { SeccionHeader } from "../../patterns";
import { Button, Grid, Paper, Text, TextField } from "../../core";

export default function UserPassEdit() {
  const { isAuthenticated, history, user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    password: null,
    new_password: null,
    confirmation_password: null
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveForm = (event) => {
    event.preventDefault();
    api
      .post(`/admin/passwords`, { user: formData })
      .then((res) => {
        console.log(res);
        setFormData({ ...formData });
        history.push('/app')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container>
      <SeccionHeader title="Alterar a senha" />
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "start",
            }}
          >
            <TextField
              variant="outlined"
              label="Senha atual"
              id="password"
              style={{ marginBottom: 10 }}
              type="password"
              value={formData.password}
              onChange={handleChange}
              name="password"
              required={true}
            />
            <TextField
              variant="outlined"
              label="Nova Senha"
              id="new_password"
              style={{ marginBottom: 10 }}
              type="password"
              value={formData.new_password}
              onChange={handleChange}
              name="new_password"
              required={true}
            />
            <TextField
              variant="outlined"
              label="Confirmar Senha"
              id="confirmation_password"
              style={{ marginBottom: 10 }}
              type="password"
              value={formData.confirmation_password}
              onChange={handleChange}
              name="confirmation_password"
              required={true}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={handleSaveForm}
            >
              Salvar
            </Button>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
