import { useState, useContext, useEffect } from 'react';

import api from '../../../services/api'
import { Button, Paper, TextField, Text } from '../../core';
import { AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

export default function ResetPasswordForm() {

    const { isAuthenticated, history } = useContext(AuthContext)

    const [forgotPassword, setForgotPassword] = useState({password: null})
    const [state, setState] = useState({status: 'fetchingData', errors: {}})

    const { id } = useParams()

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/app')
            return
        }

        api.get(`/api/edge/forgot-password/${id}`).then(res => {
            setState({ ...state, status: 'found'})
        }).catch(_ => {
          setState({ ...state, status: 'notFound'})
        })
    },[isAuthenticated])

    const handleChange = (event) => {
        const { name, value } = event.target

        setForgotPassword({
            ...forgotPassword,
            [name]: value
        })
    };

    const submitForm = async (event) => {
        event.preventDefault();
        api.patch(`/api/edge/forgot-password/${id}`, { forgotPassword}).then(res => {
            setState({ ...state, status: 'success' })
        }).catch(err => {
          console.log(err)
          const errors = {}
          err.response.data.errors.map((error) => {
              const field = error.field.split('.')[1]
              errors[field] = error.message
          })

          setState({
              ...state,
              errors: errors
          })
        })
    };

    const redirectToForgotPassword = async (event) => {
        event.preventDefault()
        history.push('/forgot-password/new')
    }

    const redirectToLogin = async (event) => {
      event.preventDefault()
      history.push('/')
    }

    return (
      <div style={styles.container}>
          <form onSubmit={submitForm}>
              <Paper style={styles.loginBox} elevation={6}>
                  <div style={styles.logoContainer}>
                      <img src='../logotipo-fundamentos.png' style={styles.logo} alt= 'Fundamentos' />
                  </div>

                  {
                    {
                      notFound: <>
                        <Text>O link acessado não é válido, por favor, reinicie o processo.</Text>
                        <Button style={styles.loginButton} variant="contained" color="primary" size='medium' onClick={redirectToForgotPassword}>Esqueci minha senha</Button>
                      </>,
                      found: <>
                        <Text>Defina uma nova senha para sua conta:</Text>
                        <TextField variant='outlined'
                                  size='small'
                                  label='Nova senha'
                                  style={styles.loginField}
                                  value={forgotPassword.password}
                                  name='password'
                                  onChange={handleChange}
                                  type='password'
                                  error={state.errors.hasOwnProperty('password')}
                                  helperText={state.errors.password}
                                  required={true}
                                  />
                        <Button style={styles.loginButton} variant="contained" color="primary" size='medium' type='Submit'>Redefinir senha</Button>
                        <div style={styles.buttonsContainer}>
                            <Button color='primary' style={{color: '#000'}} size='small' onClick={redirectToLogin}>Voltar para login</Button>
                        </div>
                      </>,
                      success: <>
                        <Text>Senha redefinida com sucesso, por favor efetue o acesso normalmente.</Text>
                        <Button style={styles.loginButton} variant="contained" color="primary" size='medium' onClick={redirectToLogin}>Voltar para login</Button>
                      </>
                    }[state.status]
                  }
              </Paper>
          </form>
      </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url("Background2.png")',
        backgroundSize: 'cover'
    },
    loginBox: {
        padding: 20,
        margin: 10,
        maxWidth: 300,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: '#fece03'
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: 25
    },
    logo: {
        maxWidth: 150
    },
    loginField: {
        marginTop: 10
    },
    loginButton: {
        width: '100%',
        height: 35,
        marginTop: 20
    },
    buttonsContainer: {
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    }
}
