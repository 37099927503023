import { Toolbar } from "@material-ui/core";

export default function Content(props) {
    return (
        <main {...props} style={styles.container}>
            <Toolbar />
            <div style={styles.content}>
            {props.children}
            </div>
            
        </main>
    )
}

const styles = {
    container: {
        flexGrow: 1,
        padding: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        flex: 1
    }
}