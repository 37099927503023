import { withStyles } from '@material-ui/core/styles';
import { Chip as MUIChip } from '@material-ui/core'

/* Props
    languageTheme (String) default 'pt' | ['pt', 'es'] - Change Object Theme
*/
const CustomChip = withStyles((theme) => ({
    root: {
        backgroundColor: ({ languageTheme = 'pt' }) => languageTheme === 'pt' ? theme.palette.primary.main : theme.palette.primaryES.main,
    }
}))(MUIChip);

export const Chip = (props) => <CustomChip {...props} />
