import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '../../core'

export const VerseEdit = ({ content: oldContent, lessonStep, handleClose, handleSave }) => {

    const {value = {}} = oldContent
    const [content, setContent] = useState(value);
    const { verse, reference} = content;

    const handleChange = ({ target }) => {
        const { name, value } = target
        setContent({
            ...content,
            [name]: value
        })
    }

    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>Edição do Versículo</DialogTitle>
            <DialogContent>
                <TextField label='Versículo' value={verse} onChange={handleChange} fullWidth multiline minRows={3} name='verse' />
                <TextField label='Referência' value={reference} onChange={handleChange} fullWidth style={{ marginTop: 10 }} name='reference' />
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="outlined" color="primary" onClick={handleClose}>Cancelar</Button>
                <Button size="small" variant="contained" color="primary" onClick={()=>{handleSave(content); handleClose()}}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}
