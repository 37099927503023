import React, { useEffect } from "react";
import moment from 'moment'
import 'moment/locale/pt-br';
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import Admin from "./components/layout/Admin";
import { AuthProvider } from './contexts/AuthContext'
import { AppProvider } from './contexts/AppContext'

function App() {

  useEffect(() => {
    moment.locale('pt-br');
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <AppProvider>
          <AuthProvider>
            <Admin />
          </AuthProvider>
        </AppProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
