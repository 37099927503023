
import { CheckboxItem } from './CheckboxItem'

const { Component } = require('react');
const { FormGroup, withStyles } = require('@material-ui/core/');


const styles = {
  'checkbox-wrapper': {
    'checkbox-head': {
      display: 'flex',
      'flex-direction': 'column',
      'margin-right': 32,
    },
    'checkbox-children': {
        display: 'flex',
        'flex-direction': 'column',
    },
    'MuiFormGroup-root-1': {
      'flex-direction': 'row'
    }
  }
}

class CustomCheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentCheckboxChecked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.updateParentWithChildren(nextProps);
  }

  componentWillMount() {
    this.updateParentWithChildren(this.props);
  }

  handleParentCheckboxChange = (isChecked) => {
    const { checkboxes, onCheckboxGroupChange } = this.props;
    const newCheckState = checkboxes.map((aCheckbox) => ({
      ...aCheckbox,
      checked: isChecked,
    }));
    onCheckboxGroupChange(newCheckState);
  };

  updateParentWithChildren = (props) => {
    const { checkboxes } = props;
    let allChecked = false;
    for (let i = 0; i < checkboxes.length; i += 1) {
      if (checkboxes[i].checked) {
        allChecked = true;
      } else {
        allChecked = false;
        break;
      }
    }
    this.setState({
      parentCheckboxChecked: allChecked,
    });
  };

  handleChildCheckboxChange = (isChecked, index) => {
    const { checkboxes } = this.props;
    const { onCheckboxGroupChange } = this.props;
    const newCheckState = checkboxes.map(
      (aCheckbox, i) => (index === i ? { ...aCheckbox, checked: isChecked } : aCheckbox)
    );
    onCheckboxGroupChange(this.props.groupLabel, newCheckState);
  };

  renderCheckboxes = () => {
    const { checkboxes } = this.props;
    if (!checkboxes) {
      return null;
    }
    return checkboxes.map((aCheckbox, index) => (
      <CheckboxItem
        key={index}
        checkboxLabel={aCheckbox.label}
        checkboxValue={aCheckbox.value}
        checked={aCheckbox.checked}
        checkboxChangeCallback={(checkStatus) => this.handleChildCheckboxChange(checkStatus, index)}
      />
    ));
  };

  render() {
    const { parentCheckboxChecked } = this.state;
    return (
      <div className="checkbox-wrapper">
        <FormGroup>
          <div className="checkbox-head">
            <CheckboxItem
              checkboxLabel={this.props.groupLabel}
              checkboxValue="all"
              checked={parentCheckboxChecked}
              checkboxChangeCallback={this.handleParentCheckboxChange}
            />
          </div>
          <div className="checkbox-children">{this.renderCheckboxes()}</div>
        </FormGroup>
      </div>
    )
  }
}

export const CheckboxGroup = (props) => <CustomCheckboxGroup {...props} />
