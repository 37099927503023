import { theme } from '../../../theme'
import { Div, Text, TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Paper } from '../../core'

export const ContentTable = ({ content }) => {

    const { value = {} } = content
    const { items = [], type = 'list' } = value

    return (

        <Table size='small' dense style={{ marginTop: 20 }}>
            <TableBody style={{ backgroundColor: 'lightgray' }}>
                {items.map((listItem, index) => (
                    <TableRow key={index} >
                        {type == 'number' &&
                            <TableCell scope="row" width={15} style={{
                                backgroundColor: theme.palette.primary.main,
                                textAlign: 'center',
                                color: '#FFF',
                                fontSize: 16,
                                borderColor: '#FFF', borderWidth: 5, borderStyle: 'solid'
                            }}>
                                {index + 1}
                            </TableCell>
                        }
                        <TableCell scope="row" style={{ borderColor: '#FFF', borderWidth: 5, borderStyle: 'solid' }}>
                            {listItem[0]}
                        </TableCell>
                        <TableCell scope="row" style={{ borderColor: '#FFF', borderWidth: 5, borderStyle: 'solid' }}>
                            {listItem[1]}
                        </TableCell>
                    </TableRow>
                ))}
                {items.length == 0 && <Div style={styles.placeholder}><Text>Insira aqui sua tabela</Text></Div>}
            </TableBody>
        </Table>

    )
}

const styles = {
    container: {
        width: '100%'
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}