import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import api from '../../../services/api'
import { Text, Grid } from '../../core'
import { Paper } from '../../core'
import { LineDetails, SeccionHeader, ConfirmDialog } from '../../patterns';

export default function TranslationDetails() {

    const [state, setState] = useState({
        showConfirmDialog: false
    });

    let { id } = useParams();
    const [translation, setTranslation] = useState({
      pt: '',
      es: '',
      en: ''
    });

    useEffect(() => {
        api.get(`/admin/translations/${id}`).then(res => {
            setTranslation(res.data.translation)
        }).catch(err => {
            console.log(err)
        })
    }, [id]);

    const handleDeleteButton = () => {
        api.delete(`/admin/translations/${translation.id}`).then(res => {
            history.push(`/app/translations`)
        }).catch(err => {
            console.log(err)
        })
    }

    const history = useHistory();
    const handleEditButton = useCallback((_) => history.push(`/app/translations/edit/${id}`), [history, id]);

    return (
        <>
            <Grid container >
                <SeccionHeader title={translation.pt.length > 50 ? translation.pt.substring(0, 50) + '...' : translation.pt} editButton editButtonAction={() => handleEditButton(translation.id)} deleteButton deleteButtonAction={() => setState({...state, showConfirmDialog: true})} />
                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Text variant='h6'>Dados da Tradução</Text>
                            </Grid>
                            <LineDetails title='Português' text={translation.pt} />
                            <LineDetails title='Inglês' text={translation.en} />
                            <LineDetails title='Espanhol' text={translation.es} />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {state.showConfirmDialog &&
                <ConfirmDialog
                    open={state.showConfirmDialog}
                    title='Traduções'
                    question={`Deseja realmente excluir a tradução '${translation.pt}'?`}
                    handleConfirm={handleDeleteButton}
                    handleClose={() => setState({...state, showConfirmDialog: false})}
                    cancelButton/>
            }
        </>
    )
}
