import theme from '../../../theme'
import {Div, Text} from '../../core'

export const ContentVerse = ({content, index}) => {

    const { value = {} } = content
    const { verse, reference } = value
    
    return(
    <div>
        {verse && <Div><Div style={styles.marker}></Div></Div>}
        {verse && <Text style={styles.verse}>{verse}</Text>}
        <Text style={styles.reference}>{reference}</Text>
        {!verse && !reference && <Div style={styles.placeholder}><Text>Insira aqui o versículo</Text></Div>}
    </div>
)}

const styles = {
    marker: {
        backgroundColor: theme.palette.primary.main,
        maxWidth: 15,
        height: 15,
        borderRadius: 10,
        marginTop: 20
    },
    verse: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: '300',
        marginTop: 20,
        marginBottom: 20,
    },
    reference: {
        fontWeight: '700',
        fontSize: 18
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}