import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../../services/api'
import { Grid, Button, Paper, TextField, Divider } from '../../core'
import { SeccionHeader, ConfirmDialog } from '../../patterns';

export default function TranslationEdit() {

    const [translation, setTranslation] = useState({
        pt: '',
        en: '',
        es: ''
    });

    let { id } = useParams();

    const trimTitle = (title) => {
        return title?.length > 50 ? title.substring(0, 50) + '...' : title
    }

    useEffect(() => {
        if (id !== 'new')
            api.get(`/admin/translations/${id}`).then(res => {
                setTranslation(res.data.translation)
                setState({ ...state, title: trimTitle(res.data.translation.pt)})
            }).catch(err => {
                console.log(err)
            })
    }, [id])

    const [state, setState] = useState({
        title: 'Tradução',
        showConfirmDialog: false,
        errors: {}
    })

    const history = useHistory();

    const handleChange = (event) => {
        const { name, value } = event.target

        setTranslation({
            ...translation,
            [name]: value
        })

        delete state.errors[name]
        setState(state)
    }

    const handleDeleteButton = () => {
        api.delete(`/admin/translations/${translation.id}`).then(res => {
            history.push(`/app/translation`)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSaveButton = () => {
        let request;

        if (translation.hasOwnProperty('id'))
            request = api.patch(`/admin/translations/${translation.id}`, { translation } )
        else
            request = api.post(`/admin/translations`, { translation })

        request.then(res => {
            const { id } = res.data.translation
            history.push(`/app/translations/${id}`)
        }).catch(err => {
            const errors = {}
            err.response.data.errors.forEach((error) => {
                const field = error.field.split('.')[1]
                errors[field] = error.message
            })
            setState({
                ...state,
                errors
            })
        })
    }

    const handleCancelButton = () => {
      if (translation.hasOwnProperty('id'))
        history.push(`/app/translations/${id}`)
      else
        history.push(`/app/translations`)
    }

    return (
        <>
            <Grid container >
                <SeccionHeader title={state.title}
                    deleteButtonAction={() => setState({ ...state, showConfirmDialog: true })} deleteButton={translation.hasOwnProperty('id')}
                />
                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <Grid container>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Português"
                                            variant="outlined"
                                            refs="pt"
                                            value={translation.pt}
                                            fullWidth
                                            name='pt'
                                            error={state.errors.hasOwnProperty('pt')}
                                            helperText={state.errors.pt}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Inglês"
                                            variant="outlined"
                                            refs="en"
                                            value={translation.en}
                                            fullWidth
                                            name='en'
                                            error={state.errors.hasOwnProperty('en')}
                                            helperText={state.errors.en}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Espanhol"
                                            variant="outlined"
                                            refs="es"
                                            value={translation.es}
                                            fullWidth
                                            name='es'
                                            error={state.errors.hasOwnProperty('es')}
                                            helperText={state.errors.es}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                          </div>


                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'flex-end' }}>
                            <Button color='secondary' variant='outlined' onClick={handleCancelButton} style={{ marginRight: 20 }}>Cancelar</Button>
                            <Button color='primary' variant='contained' onClick={handleSaveButton}>Salvar</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            {state.showConfirmDialog &&
                <ConfirmDialog
                    open={state.showConfirmDialog}
                    title='Usuários'
                    question={`Deseja realmente excluir a tradução '${translation.id}'?`}
                    handleConfirm={handleDeleteButton}
                    handleClose={() => setState({ ...state, showConfirmDialog: false })}
                    cancelButton />
            }
        </>
    )
}
