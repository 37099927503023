import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import api from '../../../services/api'
import { Text, Grid } from '../../core'
import { Paper } from '../../core'
import { LineDetails, SeccionHeader, ConfirmDialog, AlertDialog } from '../../patterns';

export default function LessonDetails() {

    const {setLoading} = useContext(AppContext)
    const [errors, setError] = useState([]);
    const [lesson, setLesson] = useState({});
    const [state, setState] = useState({
        showConfirmDialog: false
    });
    let { id } = useParams();

    const [alert, setAlert] = useState({
        show: false,
        errors: [{ message: 'Ocorreu um erro' }]
    })

    useEffect(() => {
        setLoading(true)
        api.get(`/admin/lessons/${id}`).then(res => {
            setLoading(false)
            setLesson(res.data.lesson)
        }).catch(err => {
            setLoading(false)
            setAlert({ errors: err.response.data.errors, show: true })
        })

    }, []);

    const handleDeleteButton = () => {
        setLoading(true)
        api.delete(`/admin/lessons/${lesson.id}`).then(res => {
            setLoading(false)
            history.push(`/app/lessons`)
        }).catch(err => {
            setLoading(false)
            setAlert({ errors: err.response.data.errors, show: true })
        })
    }

    

    const history = useHistory();
    const handleEditButton = useCallback(() => history.push(`/app/lessons/edit/${id}`), [history]);

    return (
        <>
            <Grid container >
                <SeccionHeader title={lesson.title} editButton editButtonAction={() => handleEditButton(lesson.id)} deleteButton deleteButtonAction={() => setState({...state, showConfirmDialog: true})} />
                <AlertDialog open={alert.show} handleClose={() => setAlert({ ...alert, show: false })} errors={alert.errors} />
                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Text variant='h6'>Dados da Lição</Text>
                            </Grid>
                            {lesson.parent && <LineDetails title='Lição Pai' text={lesson.parent.title} />}
                            <LineDetails title='Título' text={lesson.title} />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {state.showConfirmDialog &&
                    <ConfirmDialog
                    open={state.showConfirmDialog}
                    title='Lições'
                    question={`Deseja realmente excluir a lição '${lesson.title}'?`}
                    handleConfirm={handleDeleteButton}
                    handleClose={() => setState({...state, showConfirmDialog: false})}
                    cancelButton/>
            }
        </>
    )
}
