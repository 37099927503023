import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Button, Paper, TextField } from '../../core';
import { AuthContext } from '../../../contexts/AuthContext';
import { CustomSnackBar } from '../../core/Snackbar';
import { Box } from '../../core';

export default function Login() {

   const { setLoading } = useContext(AppContext);
   const { signIn, isAuthenticated, history } = useContext(AuthContext)
   const [statusMessage, setStatusMessage] = useState({ severity: "error", message: "Um erro aconteceu." })
   const [showSnackBar, setShowSnackBar] = useState(false)
   const [credentials, setCredentials] = useState({
      email: '',
      password: ''
   });

   useEffect(() => {
      if (isAuthenticated) {
         history.push('/app')
      }
   }, [history, isAuthenticated])

   const handleChange = (event) => {
      const { name, value } = event.target

      setCredentials({
         ...credentials,
         [name]: value
      })
   };

   const submitForm = (event) => {
      if (credentials.email === "") {
         setStatusMessage({ severity: "warning", message: "O campo E-mail está vazio." })
         setShowSnackBar(true)
         return false
      }
      if (credentials.password === "") {
         setStatusMessage({ severity: "warning", message: "O campo Senha está vazio." })
         setShowSnackBar(true)
         return false
      }
      event.preventDefault();
      setLoading(true);
      signIn(credentials).then(response => {
         history.push('/app')
         setLoading(false);
      }).catch((err) => {
         console.log(err);
         setStatusMessage({ severity: "error", message: "E-mail ou Senha inválidos." })
         setShowSnackBar(true)
         setLoading(false);
      })
   };

   const redirectToForgotPassword = async (event) => {
      event.preventDefault();
      history.push('/forgot-password/new')
   }

   return (
      <div style={styles.container}>
         <form style={{...styles.container}}>
            <Paper style={styles.loginBox} elevation={6}>
               <div style={styles.logoContainer}>
                  <img src='logotipo-fundamentos.png' style={styles.logo} alt='Fundamentos' />
               </div>
               <TextField variant='outlined'
                  size='small'
                  label='E-mail'
                  style={styles.loginField}
                  value={credentials.email}
                  name='email'
                  onChange={handleChange}
                  type='email'
               />
               <TextField variant='outlined'
                  size='small'
                  label='Senha'
                  type='password'
                  style={styles.loginField}
                  value={credentials.password}
                  name='password'
                  onChange={handleChange}
               />
               <Button style={styles.loginButton} variant="contained" color="primary" size='medium' onClick={submitForm}>Entrar</Button>
               <div style={styles.buttonsContainer}>
                  <Button color='primary' style={{ color: '#000' }} size='small' onClick={redirectToForgotPassword}>Esqueci minha senha</Button>
               </div>
            </Paper>
         </form>
         {showSnackBar && <CustomSnackBar severity={statusMessage.severity} message={statusMessage.message} open={showSnackBar} handleClose={() => setShowSnackBar(false)} />}
      </div>
   )
}

const styles = {
   container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
   },
   loginBox: {
      padding: 20,
      margin: 10,
      maxWidth: 300,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
   },
   logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: 25
   },
   logo: {
      maxWidth: 150
   },
   loginField: {
      marginTop: 10
   },
   loginButton: {
      width: '100%',
      height: 35,
      marginTop: 20
   },
   buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10
   },
}
