import { Text } from '../../core'
import { Icon } from '../../core/Icons'

export const VideoCell = ({ urlVideo, urlUploadedVideo, title = '' }) => {

    return (
        <>
            <Text style={styles.subTitleText}>{title}</Text>
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <Icon
                    className="fab fa-youtube"
                    style={styles.videoCell}
                    color={urlVideo ? 'primary' : 'lightgray'}
                />
                <Icon
                    className="fa fa-cloud-upload"
                    style={styles.videoCell}
                    color={urlUploadedVideo ? 'primary' : 'lightgray'}
                />
            </div>
        </>
    )

}

const styles = {
    subTitleText: {
        textAlign: 'center',
        fontSize: 10,
        whiteSpace: 'nowrap'
    },
    videoCell: {
        height: 25,
        width: 30,
        margin: '5px 5px 0px 5px'
    }
}