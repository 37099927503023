import { Text } from '../../core'
import { Icon } from '../../core/Icons'

export const PodcastCell = ({ applePodcast, deezerPodcast, spotifyPodcast, googlePodcast, title = '' }) => {

    return (
        <>
            <Text style={styles.subTitleText}>{title}</Text>
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <Icon
                    className="fab fa-apple"
                    style={styles.podcastCell}
                    color={applePodcast ? 'primary' : 'lightgray'}
                />
                <Icon
                    className="fab fa-deezer"
                    style={styles.podcastCell}
                    color={deezerPodcast ? 'primary' : 'lightgray'}
                />
                <Icon
                    className="fab fa-spotify"
                    style={styles.podcastCell}
                    color={spotifyPodcast ? 'primary' : 'lightgray'}
                />
                <Icon
                    className="fab fa-google-plus"
                    style={styles.podcastCell}
                    color={googlePodcast ? 'primary' : 'lightgray'}
                />
            </div>
        </>
    )
}

const styles = {
    subTitleText: {
        textAlign: 'center',
        fontSize: 10,
        whiteSpace: 'nowrap'
    },
    podcastCell: {
        height: 25,
        width: 25,
        margin: '5px 5px 0px 5px'
    },
}