import {theme} from '../../../theme'
import { Div, Text } from '../../core'

export const ContentText = ({ content }) => {

    const { value = {} } = content
    const { title, subtitle, text } = value
    return (
        <div style={styles.container}>
            {title && <Text style={styles.title}>{title}</Text>}
            {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
            {text && <Text style={styles.text}>{text}</Text>}
            {!text && !title && !subtitle && <Div style={styles.placeholder}><Text>Insira aqui seu texto</Text></Div>}
        </div>
    )
}

const styles = {
    container: {
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 20,
        fontWeight: '700',
        color: theme.palette.primary.main,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    subtitle: {
        fontWeight: '700',
        fontSize: 14,
        marginTop: 10
    }, 
    text: {
        marginTop: 20,
        fontSize: 14,
        fontWeight: '400',
        width: 300,
        wordWrap: 'break-word'
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}