import { Select as MUISelect } from '@material-ui/core';
import { InputLabel, FormControl, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const Select = (props) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 20, ...props.style }} fullWidth>
            <InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>
            <MUISelect
                labelId={`${props.id}-label`}
                id={props.id}
                value={props.value}
                onChange={props.handleChange}
                label={props.label}
                name={props.name}
            >
                {props.children}
            </MUISelect>
        </FormControl>
    )
}