import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '../../core'

export const VideoEdit = ({ content: oldContent, handleClose, handleSave }) => {

    const { value = {} } = oldContent
    const [content, setContent] = useState(value);
    const { url  } = content

    const handleChange = ({ target }) => {
        const { name, value } = target
        setContent({
            ...content,
            [name]: value
        })
    }

    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>Edição do Vídeo</DialogTitle>
            <DialogContent>
                <TextField label='URL' value={url} onChange={handleChange} fullWidth style={{ marginTop: 10 }} name='url' />
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="outlined" color="primary" onClick={handleClose}>Cancelar</Button>
                <Button size="small" variant="contained" color="primary" onClick={() => { handleSave(content); handleClose() }}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}
