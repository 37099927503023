import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  SeccionHeader,
  ConfirmDialog,
  AlertDialog,
  Upload,
} from "../../patterns";
import {
  Grid,
  Button,
  Paper,
  TextField,
  Divider,
  Avatar,
  InnerLoading,
} from "../../core";

export default function UserProfile() {
  const { setLoading } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { id } = user;

  const [currentUser, setCurrentUser] = useState({
    name: "",
    email: "",
    city: "",
    state: "",
    country: "",
    phone: {
      number: "",
      ddi: "",
    },
    avatar: null,
  });

  useEffect(() => {
    setLoading(true);
    api
      .get(`/admin/users/${id}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setCurrentUser(res.data.user);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ errors: err.response.data.errors, show: true });
      });
  }, [id]);

  const [state, setState] = useState({
    showConfirmDialog: false,
    fileLoading: false,
    showPermissionDialog: false,
  });

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });

  const history = useHistory();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCurrentUser({
      ...currentUser,
      [name]: value,
    });
  };

  const handlePhoneChange = (event) => {
    const { name, value } = event.target;
    currentUser.phone[name] = value;
    setCurrentUser({
      ...currentUser,
    });
  };

  const handleSaveButton = () => {
    setLoading(true);
    console.log(currentUser);
      api
        .patch(`/admin/users/${user.id}`, { currentUser })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ errors: err.response.data.errors, show: true });
        });
  };

  const handleUploadFile = (file) => {
    if (file[0]) {
      setLoading(true);
      const dataFormFile = new FormData();
      dataFormFile.append("file", file[0]);

      api
        .post(`/upload`, dataFormFile)
        .then((res) => {
          setLoading(false);
          const { url } = res.data;

          setCurrentUser({
            ...currentUser,
            avatar: url,
          });

          setState({
            ...state,
            fileLoading: false,
          });
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ errors: err.response.data.errors, show: true });
        });
    }
  };

  return (
    <>
      <Grid container>
        <SeccionHeader
          title={id ? currentUser.name || currentUser.email : "Novo usuário"}
          deleteButtonAction={() =>
            setState({ ...state, showConfirmDialog: true })
          }
        />
        <AlertDialog
          open={alert.show}
          handleClose={() => setAlert({ ...alert, show: false })}
          errors={alert.errors}
        />
        <Grid item xs={12}>
          <Paper
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      label="Nome"
                      variant="outlined"
                      refs="name"
                      value={currentUser.name}
                      fullWidth
                      name="name"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="E-mail"
                      variant="outlined"
                      refs="email"
                      value={currentUser.email}
                      fullWidth
                      name="email"
                      style={{ marginTop: 20 }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Cidade"
                      variant="outlined"
                      refs="city"
                      value={currentUser.city}
                      name="city"
                      style={{
                        marginRight: 20,
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Estado"
                      variant="outlined"
                      refs="state"
                      value={currentUser.state}
                      name="state"
                      style={{
                        marginRight: 20,
                        marginTop: 20,
                        marginBottom: 10,
                        width: 250,
                      }}
                      onChange={handleChange}
                    />
                    <TextField
                      label="País"
                      variant="outlined"
                      refs="country"
                      value={currentUser.country}
                      name="country"
                      style={{
                        marginRight: 20,
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="DDI"
                      variant="outlined"
                      refs="ddi"
                      value={currentUser.phone.ddi}
                      name="ddi"
                      style={{
                        marginRight: 20,
                        marginTop: 10,
                        marginBottom: 10,
                        width: 60,
                      }}
                      onChange={handlePhoneChange}
                    />
                    <TextField
                      label="Telefone"
                      variant="outlined"
                      refs="number"
                      value={currentUser.phone.number}
                      name="number"
                      style={{
                        marginRight: 20,
                        marginTop: 10,
                        marginBottom: 10,
                        width: 150,
                      }}
                      onChange={handlePhoneChange}
                    />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxWidth: 250,
                  paddingLeft: 20,
                  justifyContent: "center",
                }}
              >
                {!currentUser.avatar && (
                  <div style={{ position: "relative", maxHeight: 250 }}>
                    {state.fileLoading && <InnerLoading />}
                    <Upload
                      acceptedFiles={["image/*"]}
                      dropzoneText={
                        "Arraste e solte seu arquivo ou clique aqui"
                      }
                      onChange={handleUploadFile}
                      showPreviewsInDropzone={false}
                      filesLimit={1}
                      onDrop={() => setState({ ...state, fileLoading: true })}
                    />
                  </div>
                )}
                {currentUser.avatar && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Avatar
                      alt={currentUser.name}
                      src={currentUser.avatar}
                      style={{ width: 200, height: 200 }}
                    />
                    <Button
                      style={{ marginTop: 20 }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => setCurrentUser({ ...currentUser, avatar: null })}
                    >
                      Remover Foto
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push(`/app/user/${id}`)}
                style={{ marginRight: 20 }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSaveButton}
              >
                Salvar
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
