import {Div, Text} from '../../core'
import {theme} from '../../../theme'

export const ContentVideo = ({content}) => {


    const {value = {}} = content
    const {url} = value

    return (
        <>
            {url && <iframe style={styles.url} width="300" height="200" src={url} title="YouTube video player" frameborder="0"></iframe>}
            {!url && <Div style={styles.placeholder}><Text>Insira aqui seu vídeo</Text></Div>}
        </>
    )
}

const styles = {
    url: {
        marginTop: 20,
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}