import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Text, Div } from '../core'

export const ConfirmDialog = (props) => {

    const { open, handleClose, handleConfirm, confimButtonLabel = 'Sim', cancelButtonLabel = 'Cancelar', question, title } = props

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <Text style={{padding: 10, fontSize: 20, fontWeight: 700}}>{title}</Text>
            <DialogContent>
                <DialogContentText>
                    {question}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant='outlined'>
                    {cancelButtonLabel}
                </Button>
                <Button onClick={handleConfirm} color="primary" variant='contained'>
                    {confimButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export const AlertDialog = (props) => {

    const { open, handleClose, confirmLabel = 'Ok', errors = ['Ocorreu um erro'], title = 'Fundamentos' } = props

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <Text style={{padding: 10, fontSize: 20, fontWeight: 700}}>{title}</Text>
            <DialogContent>
                <DialogContentText>
                    {/* {errors.map((item, key)=>(<Div key={key}>{item.message}</Div>))} */}
                    Ocorreu um erro
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant='outlined'>
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}