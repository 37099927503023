import { createContext, useEffect, useState } from "react";
import { setCookie, parseCookies, destroyCookie } from 'nookies'

import api from "../services/api";
import { recoverUserInformation, signInRequest } from "../services/auth";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext({});

// Session cookie expires every hour
const SESSION_COOKIE_MAX_AGE = 60 * 60;

// Validates sessions every minute
const VALIDATE_TIMEOUT = 60 * 1000;

const SESSION_COOKIE = 'fundamentos.token';

export function AuthProvider({ children }) {
    const history = useHistory();

    const [user, setUser] = useState();

    const isAuthenticated = !!user;

    const [loadingCurrentUser, setLoadingCurrentUser] = useState(true)

    useEffect(() => {
        const token = getSessionCookie()

        if (token && token !== 'undefined') {
            setApiAuthorizationHeader(token)
            recoverUserInformation().then(response => {
                setUser(response.user)
                setLoadingCurrentUser(false)
            })
        } else {
            setLoadingCurrentUser(false)
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated) validateSignedUser()
    })

    const getSessionCookie = () => {
        const { [SESSION_COOKIE]: token } = parseCookies()

        return token
    }

    const setApiAuthorizationHeader = (token) => {
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
    }

    const validateSignedUser = async () => {
        if (getSessionCookie())
            setTimeout(validateSignedUser, VALIDATE_TIMEOUT)
        else
            signOut()
    }

    const signIn = async ({ email, password }) => {
        const { token, user, errors } = await signInRequest({
            email,
            password,
        })

        if (token) {
            setCookie(undefined, SESSION_COOKIE, token, {
                maxAge: SESSION_COOKIE_MAX_AGE
            })
    
            setApiAuthorizationHeader(token)
            setUser(user)    
            validateSignedUser()
        } else {
            throw new Error('Error loggin in', errors)
        }
        
    }

    const signOut = () => {
        setLoadingCurrentUser(true)
        destroyCookie(undefined, SESSION_COOKIE)
        delete api.defaults.headers.delete['Authorization']

        setUser()
        history.push('/')
        setLoadingCurrentUser(false)
    }

    const userHasPermission = (permission) => {
        return user?.permissions?.includes(permission)
    }

    return (
        <AuthContext.Provider value={{ user, history, isAuthenticated, signIn, signOut, loadingCurrentUser, userHasPermission }}>
            {children}
        </AuthContext.Provider>
    )
};
