import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

export function PrivateRoute({ component: Component, ...rest }) {
    const { isAuthenticated } = useContext(AuthContext);

    return(
        isAuthenticated && <Route {...rest} /> || <Redirect push to="/" />
    )
}
