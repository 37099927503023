import { Text, TextField} from '../../core'
import { Icon } from '../../core/Icons'
import { DurationField } from '../../patterns'

export const PodcastFormFields = ({ lesson, handleChange }) => {
    return (
        <>
            <Text variant='h6' style={{ marginTop: 20 }}>PODCASTS</Text>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Deezer"
                    variant="outlined"
                    refs="urlDeezer"
                    value={lesson.urlDeezer}
                    fullWidth
                    name='urlDeezer'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10 }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationDeezer}
                    name='durationDeezer'
                />
                <Icon
                    className="fab fa-deezer"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlDeezer !== '' ? window.open(`${lesson.urlDeezer}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Apple Podcast"
                    variant="outlined"
                    refs="urlApplePodcast"
                    value={lesson.urlApplePodcast}
                    fullWidth
                    name='urlApplePodcast'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10  }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationApplePodcast}
                    name='durationApplePodcast'
                />
                <Icon
                    className="fa fa-apple"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlApplePodcast !== '' ? window.open(`${lesson.urlApplePodcast}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Google Podcast"
                    variant="outlined"
                    refs="urlGooglePodcast"
                    value={lesson.urlGooglePodcast}
                    fullWidth
                    name='urlGooglePodcast'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10  }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationGooglePodcast}
                    name='durationGooglePodcast'
                />
                <Icon
                    className="fab fa-google-plus"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlGooglePodcast !== '' ? window.open(`${lesson.urlGooglePodcast}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Spotify"
                    variant="outlined"
                    refs="urlSpotify"
                    value={lesson.urlSpotify}
                    fullWidth
                    name='urlSpotify'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10  }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationSpotify}
                    name='durationSpotify'
                />
                <Icon
                    className="fab fa-spotify"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlSpotify !== '' ? window.open(`${lesson.urlSpotify}`, '_blank').focus() : null}
                />
            </div>
        </>
    )
}

export const PodcastShortFormFields = ({ lesson, handleChange}) => {


    return (
        <>
            <Text variant='h6' style={{ marginTop: 20 }}>PODCASTS - Versão Resumida</Text>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Deezer"
                    variant="outlined"
                    refs="urlDeezerShort"
                    value={lesson.urlDeezerShort}
                    fullWidth
                    name='urlDeezerShort'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10  }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationDeezerShort}
                    name='durationDeezerShort'
                />
                <Icon
                    className="fab fa-deezer"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlDeezerShort !== '' ? window.open(`${lesson.urlDeezerShort}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Apple Podcast"
                    variant="outlined"
                    refs="urlApplePodcastShort"
                    value={lesson.urlApplePodcastShort}
                    fullWidth
                    name='urlApplePodcastShort'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10   }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationApplePodcastShort}
                    name='durationApplePodcastShort'
                />
                <Icon
                    className="fa fa-apple"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlApplePodcastShort !== '' ? window.open(`${lesson.urlApplePodcastShort}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Google Podcast"
                    variant="outlined"
                    refs="urlGooglePodcastShort"
                    value={lesson.urlGooglePodcastShort}
                    fullWidth
                    name='urlGooglePodcastShort'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10   }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationGooglePodcastShort}
                    name='durationGooglePodcastShort'
                />
                <Icon
                    className="fab fa-google-plus"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlGooglePodcastShort !== '' ? window.open(`${lesson.urlGooglePodcastShort}`, '_blank').focus() : null}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <TextField
                    label="Spotify"
                    variant="outlined"
                    refs="urlSpotifyShort"
                    value={lesson.urlSpotifyShort}
                    fullWidth
                    name='urlSpotifyShort'
                    onChange={handleChange}
                    style={{ marginBottom: 20, marginRight: 10   }}
                />
                <DurationField 
                    handleChange={handleChange} 
                    value={lesson.durationSpotifyShort} 
                    name='durationSpotifyShort'
                />
                <Icon
                    className="fab fa-spotify"
                    style={{ height: 50, width: 50, marginLeft: 10, cursor: 'pointer', marginTop: 15 }}
                    color='primary'
                    onClick={() => lesson.urlSpotifyShort !== '' ? window.open(`${lesson.urlSpotifyShort}`, '_blank').focus() : null}
                />
            </div>
        </>
    )
}
