export default function Background(props) {
    return (
        <div style={styles.container} {...props} />
    )
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f4f6f8',
        display: 'flex'
    }
}