import { useState } from 'react'
import api from '../../../services/api'
import { theme } from '../../../theme'
import { Div, Text } from '../../core'

export const ContentList = ({ content }) => {

    const { value = {} } = content
    const { items = [] } = value
    const {type = 'list'} = value
    return (
        <div style={styles.container}>
            {items.map((listItem, index) => (
                <div key={`${index}`} style={{ flex: 1, display: 'flex', flexDirection: 'row', paddingLeft: 20, paddingRight: 20, marginTop: 20}}>
                    <div style={{ minWidth: 36, maxHeight: 36, borderRadius: '50%', backgroundColor: theme.palette.primary.main, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ color: '#FFF', fontSize: 22 }}>{type === "number" ? index+1 : ''}</Text>
                    </div>
                    <Text style={{ fontSize: 14, marginLeft: 10 }}>{listItem}</Text>
                </div>
            ))}
            {items.length == 0 && <Div style={styles.placeholder}><Text>Insira aqui sua lista</Text></Div>}
        </div>

    )
}

const styles = {
    container: {
        width: '100%'
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}