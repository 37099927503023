import {theme} from '../../../theme'
import {Div, Text} from '../../core'


export const ContentQuotation = ({content, index}) => {
    const { value = {} } = content
    const { quotation, author} = value
    return (
        <div style={styles.container}>
            {quotation && <Div><Div style={styles.marker}></Div></Div>}
            {quotation && <Text style={styles.quotation}>{quotation}</Text>}
            {author && <Text style={styles.autor}>{author}</Text>}
            {!quotation && !author && <Div style={styles.placeholder}><Text>Insira aqui a citação</Text></Div>}
        </div>
    )
}

const styles = {
    marker: {
        backgroundColor: theme.palette.primary.main,
        maxWidth: 15,
        height: 15,
        borderRadius: 10,
        marginTop: 20
    },
    container: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: '300',
        marginTop: 20,
        marginBottom: 20,
    },
    quotation: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: '300',
        marginTop: 20,
        marginBottom: 20,
    },
    author: {
        fontWeight: '700',
        fontSize: 18
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}
