import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '../../core'

export const QuotationEdit = ({ lessonStep, handleClose, handleSave }) => {

    const [lesson, setLesson] = useState({ ...lessonStep })
    const { quotation = '', author = '' } = lesson

    const handleChange = ({ target }) => {
        const { name, value } = target
        setLesson({
            ...lesson,
            [name]: value
        })
    }

    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>Edição da Citação</DialogTitle>
            <DialogContent>
                <TextField label='Citação' value={quotation} onChange={handleChange} fullWidth multiline minRows={3} name='quotation' />
                <TextField label='Autor' value={author} onChange={handleChange} fullWidth style={{ marginTop: 10 }} name='author' />
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="outlined" color="primary" onClick={handleClose}>Cancelar</Button>
                <Button size="small" variant="contained" color="primary" onClick={()=>{handleSave(lesson); handleClose()}}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}
