import React from 'react';
import { Snackbar } from '../core/index';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackBar = ({ handleClose, severity, message, open}) => {
   return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
         <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
         </Alert>
      </Snackbar>
   );
}