import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api'
import { Paper, IconButton, Text, Table, TableBody, TableCell, TableContainer, TableRow, TranslationCell, Box } from '../../core'
import { SecctionContainer, SeccionHeader } from '../../patterns'
import { EditIcon } from '../../core/Icons';
import { AppContext } from '../../../contexts/AppContext'
import theme from '../../../theme';

export default function CyclesList() {

    const { setLoading } = useContext(AppContext)
    const [cycles, setCycles] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            api.get(`/admin/cycles?sort=number:asc`)
                .then(res => {
                    setLoading(false)
                    setCycles(res.data.cycles)
                }).catch(err => {
                    setLoading(false)
                })
        }
        fetchData()
    }, [setLoading]);

    const history = useHistory();

    const supportedTranslations = (cycle) => {
        const supportedKeys = ['pt']
        if (cycle.title_en) supportedKeys.push('en')
        if (cycle.title_es) supportedKeys.push('es')
        return supportedKeys;
    }

    return (
        <SecctionContainer>
            <SeccionHeader title='Ciclos' newButtonAction={() => history.push(`cycles/new`)} newButton />
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableBody>
                        {cycles.map((cycle) => (
                            <TableRow key={cycle.id} hover>
                                <TableCell align="left" width={60}>
                                    <Box sx={styles.numberContainer}><Text>{cycle.number}</Text></Box>
                                </TableCell>
                                <TableCell align="left">
                                    <Text>{cycle.title}</Text>
                                </TableCell>
                                <TableCell align="left">
                                    <TranslationCell
                                        title={'Traduções'}
                                        supportedTranslations={supportedTranslations(cycle)}/>
                                </TableCell>
                                <TableCell align="left" width={30}>
                                    <IconButton aria-label="Editar" color="primary" onClick={()=>history.push(`cycles/${cycle.id}`)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </SecctionContainer>
    )
}

const styles = {
    numberContainer: {
        backgroundColor: theme.palette.primary.main,
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        color: '#FFF'
    }
}
