import api from "./api";

export async function signInRequest(credentials) {
  return api
    .post(`/login`, credentials)
    .then((res) => {
      const { data, token } = res.data;
      const user = {
        email: data.email,
        permissions: data.permissions,
        id: data.userId,
        avatar: data.avatar
      };
      return { token, user };
    })
    .catch((err) => {
      return err.response.data;
    });
}

export async function recoverUserInformation() {
  return api
    .get(`/login`)
    .then((res) => {
      const { data } = res.data;
      return { user: data };
    })
    .catch((err) => {
      console.log(err);
      return { error: "Error" };
    });
}
