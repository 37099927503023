import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext'
import api from '../../../services/api'
import { Grid, Button, Paper, TextField, Divider, MenuItem, Select, Text, Box, IconButton } from '../../core'
import { SeccionHeader } from '../../patterns';
import { HighlightOffIcon } from '../../core/Icons';
import { LessonSelectDialog } from '../../patterns/lesson/LessonSelectDialog';

export default function CycleEdit() {
    const { setLoading } = useContext(AppContext)

    const [cycle, setCycle] = useState({
        title_pt: '',
        title_es: '',
        title_en: '',
        active: false,
        number: '',
        lessons: []
    });

    let { id } = useParams();
    const [showLessonSelectDialog, setShowLessonSelectDialog] = useState(false)

    const history = useHistory();

    useEffect(() => {
        if (id !== 'new'){
            setLoading(true)
            api.get(`/admin/cycles/${id}`).then(res => {
                setLoading(false)
                setCycle(res.data.cycle)
            }).catch(err => {
                setLoading(false)
            })
        }
    }, [id, setLoading]);

    const handleChange = (event) => {
        const { name, value } = event.target

        setCycle({
            ...cycle,
            [name]: value
        })
    }

    const handleCancelButton = () => {
        history.push('/app/cycles')
    }

    const handleSaveButton = () => {
        const data = { cycle: { ...cycle, lessons: cycle.lessons.map(item => item.id) } }
        if (id !== 'new'){
            api.patch(`/admin/cycles/${id}`, data).then(res => {
                history.push(`/app/cycles`)
            })}
        else {
            api.post(`/admin/cycles`, data).then(res => {
                history.push(`/app/cycles`)
            })}
    }

    const handleSelect = (lesson) => {
        setShowLessonSelectDialog(false)

        setCycle({
            ...cycle,
            lessons: [
                ...cycle.lessons,
                lesson
            ]
        })
    }

    const handleCloseDialog = () => {
        setShowLessonSelectDialog(false)
    }

    const handleDeleteLesson = (lessonId) => {
        setCycle({
            ...cycle,
            lessons: cycle.lessons.filter(lesson => lesson.id !== lessonId)
        })
    }

    return (
        <>
            <Grid container >
                <SeccionHeader title={cycle.title} />

                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <TextField
                                            label="Título em português"
                                            variant="outlined"
                                            refs="title_pt"
                                            value={cycle.title_pt}
                                            fullWidth
                                            name='title_pt'
                                            onChange={handleChange}
                                            style={{ marginBottom: 20 }}
                                        />
                                        <TextField
                                            label="Título em inglês"
                                            variant="outlined"
                                            refs="title_en"
                                            value={cycle.title_en}
                                            fullWidth
                                            name='title_en'
                                            onChange={handleChange}
                                            style={{ marginBottom: 20 }}
                                        />
                                        <TextField
                                            label="Título em espanhol"
                                            variant="outlined"
                                            refs="title_es"
                                            value={cycle.title_es}
                                            fullWidth
                                            name='title_es'
                                            onChange={handleChange}
                                            style={{ marginBottom: 20 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} style={{ paddingLeft: 10 }}>
                                        <TextField
                                            label="Número"
                                            variant="outlined"
                                            refs="number"
                                            fullWidth
                                            value={cycle.number}
                                            name='number'
                                            onChange={handleChange}

                                        />
                                        <Select
                                            label='Ativo'
                                            id='active'
                                            fullWidth
                                            name='active'
                                            handleChange={handleChange}
                                            value={cycle.active}
                                        >
                                            <MenuItem value={true}>Ativo</MenuItem>
                                            <MenuItem value={false}>Inativo</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Divider />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ flex: 1 }}>
                                <Text variant='h6' style={{ marginTop: 20 }}>Lições</Text>
                            </Box>
                            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <Button onClick={() => setShowLessonSelectDialog(true)} color='primary' variant='outlined' size='small'>Adicionar Lição</Button>
                            </Box>
                        </Box>
                        <Box>
                            {cycle.lessons.map(lesson => (
                                <Box key={lesson.id} sx={{ padding: 10, flexDirection: 'row', flex: 1, display: 'flex', justifyContent: 'space-between'}}>
                                    <Text>{lesson.title}</Text>
                                    <IconButton size='small' onClick={()=>handleDeleteLesson(lesson.id)}>
                                        <HighlightOffIcon size='small'/>
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'flex-end' }}>
                            <Button color='secondary' variant='outlined' onClick={handleCancelButton} style={{ marginRight: 20 }}>Cancelar</Button>
                            <Button color='primary' variant='contained' onClick={handleSaveButton}>Salvar</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            {showLessonSelectDialog && <LessonSelectDialog handleSelect={handleSelect} handleClose={handleCloseDialog}/>}
        </>
    )
}
