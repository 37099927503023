import { Text } from './'

export const TranslationCell = ({ title, supportedTranslations }) => {
    const colorBasedOnSupport = (translation) => {
        return supportedTranslations.includes(translation) ? 'primary' : 'initial'
    }
    return (
        <div style={styles.subTitleContainer}>
            <Text style={styles.subTitleText}>{title}</Text>
            <div style={styles.subLanguageContainer}>
                <Text color={ colorBasedOnSupport('pt') } style={styles.subLanguageText}>PT</Text>
                <Text color={ colorBasedOnSupport('en') } style={styles.subLanguageText}>EN</Text>
                <Text color={ colorBasedOnSupport('es') } style={styles.subLanguageText}>ES</Text>
            </div>
        </div>
    )
}

const styles = {
    subTitleContainer: {
        borderRight: '1px solid lightgray',
        padding: '0px 10px'
    },
    subTitleText: {
        textAlign: 'center',
        fontSize: 10,
        whiteSpace: 'nowrap'
    },
    podcastCell: {
        height: 25,
        width: 25,
        margin: '5px 5px 0px 5px'
    },
    subLanguageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    subLanguageText: {
        fontWeight: 700,
        fontSize: 12,
        margin: '10px 5px'
    },
}
