import { useTheme } from '@material-ui/core/styles';
import { Button, Text } from '../core'

/* Props
    title (String) default '' - Set Header Title
    editButton (Boolean) default false - Hide/Show edit button
    deleteButton (Boolean) default false - Hide/Show delete button
    newButton (Boolean) default false - Hide/Show new button
    editButtonAction (Function) - Handle Edit Button
*/

export const SeccionHeader = (props) => {

   const {
      title = '',
      deleteButton = false,
      editButton = false,
      newButton = false,
      style,
      editButtonAction,
      deleteButtonAction,
      newButtonAction } = props

   const theme = useTheme();

   return (
      <div style={{ ...{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 20 }, ...style }}>
         <div item xs={6} style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <div style={{ width: 10, height: 50, backgroundColor: theme.palette.primary.main, marginRight: 10 }}></div>
            <Text variant='h5'>{title}</Text>
         </div>
         <div item xs={6} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>

            {editButton &&
               <Button variant='contained' size='small' color='primary' onClick={editButtonAction} >
                  <Text style={{ fontSize: 12 }}>Editar</Text>
               </Button>
            }
            {newButton &&
               <Button variant='contained' size='small' color='primary' onClick={newButtonAction} >
                  <Text style={{ fontSize: 12 }}>Novo</Text>
               </Button>
            }
            {deleteButton &&
               <Button variant='outlined' size='small' color='secondary' style={{ marginLeft: 10 }} onClick={deleteButtonAction}>
                  <Text style={{ fontSize: 12 }}>Excluir</Text>
               </Button>
            }
         </div>
      </div>
   )
}