import { useContext } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Button, Typography, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import TranslateIcon from '@material-ui/icons/Translate';
import MenuBook from '@material-ui/icons/MenuBook';
import GroupIcon from '@material-ui/icons/Group';
import LoopIcon from '@material-ui/icons/Loop';
import Background from './Background'
import Content from './Content';
import { Loading, Spacer } from '../core'

import UserList from '../pages/user/UserList'
import UserDetails from '../pages/user/UserDetails'
import UserEdit from '../pages/user/UserEdit'

import LessonsList from '../pages/lessons/LessonsList'
import LessonEdit from '../pages/lessons/LessonEdit'
import LessonDetails from '../pages/lessons/LessonDetails'
import LessonStudio from '../pages/lessons/LessonStudio'

import CyclesList from '../pages/cycles/CyclesList'
import CycleEdit from '../pages/cycles/CycleEdit'

import TranslationsList from '../pages/translations/TranslationsList'
import TranslationEdit from '../pages/translations/TranslationEdit'
import TranslationDetails from '../pages/translations/TranslationDetails'

import { AuthContext } from '../../contexts/AuthContext';
import { AppContext } from '../../contexts/AppContext';
import { PrivateRoute } from '../core/PrivateRoute';
import { UserMenu } from '../patterns/UserMenu';
import UserPassEdit from '../pages/user/UserPassEdit';
import UserProfile from '../pages/user/UserProfile';


function Application(props) {
    const { classes } = props;
    const { signOut, user, userHasPermission } = useContext(AuthContext)
    const { loading } = useContext(AppContext)

    return (
        <Background>
            <AppBar position="fixed" color='primary' className={classes.appBar} >
                <Toolbar>
                    <Link className={classes.linkLogo} to='/app'>
                        <img src='/LogoVazado_03.svg' className={classes.logo} style={{ width: 220 }} alt='Podcast Fundamentos logo' />
                    </Link>
                    <Spacer />
                    <UserMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        {
                            userHasPermission('user-read') &&
                            <Link to="/app/user">
                                <ListItem button key={0}>
                                    <ListItemIcon><GroupIcon /></ListItemIcon>
                                    <ListItemText style={{ fontSize: 16, fontWeight: 500 }} primary='Usuários' />
                                </ListItem>
                            </Link>
                        }
                        {
                            userHasPermission('lesson-read') &&
                            <Link to="/app/lessons">
                                <ListItem button key={1}>
                                    <ListItemIcon><MenuBook /></ListItemIcon>
                                    <ListItemText style={{ fontSize: 16, fontWeight: 500 }} primary='Ensinos' />
                                </ListItem>
                            </Link>
                        }
                        {
                            userHasPermission('lesson-read') &&
                            <Link to="/app/cycles">
                                <ListItem button key={0}>
                                    <ListItemIcon><LoopIcon/></ListItemIcon>
                                    <ListItemText style={{ fontSize: 16, fontWeight: 500 }} primary='Ciclos' />
                                </ListItem>
                            </Link>
                        }
                        {
                            userHasPermission('translation-read') &&
                            <Link to="/app/translations">
                                <ListItem button key={1}>
                                    <ListItemIcon><TranslateIcon /></ListItemIcon>
                                    <ListItemText style={{ fontSize: 16, fontWeight: 500 }} primary='Traduções' />
                                </ListItem>
                            </Link>
                        }
                    </List>
                </div>
            </Drawer>
            <Content>
                <Switch>
                    <PrivateRoute exact path="/app/user">
                        <UserList />
                    </PrivateRoute>
                    <PrivateRoute path="/app/user/:id" exact>
                        <UserDetails />
                    </PrivateRoute>
                    <PrivateRoute path="/app/user/edit/:id" exact>
                        <UserEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/app/user/profile/:id" exact>
                        <UserProfile />
                    </PrivateRoute>
                    <PrivateRoute path="/app/user/changepassword/:id/">
                        <UserPassEdit />
                    </PrivateRoute>
                    <PrivateRoute exact path="/app/lessons">
                        <LessonsList />
                    </PrivateRoute>
                    <PrivateRoute path="/app/lessons/:id" exact>
                        <LessonDetails />
                    </PrivateRoute>
                    <PrivateRoute path="/app/lessons/edit/:id" exact>
                        <LessonEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/app/lessons/:id/studio" exact>
                        <LessonStudio />
                    </PrivateRoute>
                    <PrivateRoute exact path="/app/translations">
                        <TranslationsList />
                    </PrivateRoute>
                    <PrivateRoute path="/app/translations/:id" exact>
                        <TranslationDetails />
                    </PrivateRoute>
                    <PrivateRoute path="/app/translations/edit/:id" exact>
                        <TranslationEdit />
                    </PrivateRoute>
                    <PrivateRoute exact path="/app/cycles">
                        <CyclesList />
                    </PrivateRoute>
                    <PrivateRoute exact path="/app/cycles/:id">
                        <CycleEdit />
                    </PrivateRoute>
                </Switch>
            </Content>
            {loading && <Loading />}
        </Background>
    )
}

const styles = theme => ({
    logo: {
        width: 50,
        marginLeft: -10
    },
    linkLogo: {
        height: 40.14,
        marginBottom: 5
    },
    spacer: {
        display: 'flex',
        flexGrow: 1
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240
    },
    drawerContainer: {
        overflow: 'auto'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
})

export default withStyles(styles, { withTheme: true })(Application)
