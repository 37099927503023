import { useState, useContext } from "react";
import api from "../../../services/api";
import { AppContext } from "../../../contexts/AppContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InnerLoading,
  Image,
  Div
} from "../../core";
import { Upload } from "../../patterns";

export const ImageEdit = ({content: oldContent, handleClose, handleSave }) => {
  const {value = {}} = oldContent

  const [content, setContent] = useState(value);
  const { url, caption } = content;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleUploadFile = (file) => {
    if (file[0]) {
      const lessonImageFile = new FormData();
      lessonImageFile.append('file', file[0]);

      api.post(`/upload`, lessonImageFile).then((res) => {
          const { url } = res.data;

          setContent({
            ...content,
            url: url,
            fileLoading: false
          })
        }).catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Edição da Imagem</DialogTitle>
      <DialogContent>
        {!content.url && (
          <Div>
            {content.fileLoading && <InnerLoading />}
            <Upload
              label="Imagem"
              value={url}
              fullwidth
              name="url"
              acceptedFiles={["image/*"]}
              dropzoneText={"Arraste e solte seu arquivo ou clique aqui"}
              onChange={handleUploadFile}
              showPreviewsInDropzone={false}
              filesLimit={1}
              onDrop={() => setContent({ ...content, fileLoading: true })}
            />
          </Div>
        )}
        {content.url && (
          <Div style={{ display: 'flex', flexDirection: 'column'}}>
            <Image alt={content.name} src={content.url} style={{ maxWidth: 200 }}/>
              <Button
                style={{ marginTop: 10 }}
                variant="outlined"
                color="secondary"
                onClick={() => setContent({ ...content, url: null })}
              >Remover Imagem</Button>
          </Div>
        )}
        <TextField
          label="Legenda"
          value={caption}
          onChange={handleChange}
          style={{ marginTop: 10}}
          fullWidth
          name="caption"
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave(content);
            handleClose();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
