import { useState } from 'react'
import api from '../../../services/api'
import {theme} from '../../../theme'
import { Div, Text } from '../../core'

export const ContentHeader = ({ content }) => {

    const { value = {} } = content
    const { title, subtitle, text } = value
    return (
        <div style={styles.headerContainer}>
            {title && <Text style={styles.title}>{title}</Text>}
        </div>
        
    )
}

const styles = {
    headerContainer: {
        backgroundImage: 'url("/headerBackground.png")',
        height: 150,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black'
    },
    title: {
        fontSize: 40,
        fontWeight: '700',
        textAlign: 'center',
        color: "#FFF",
        fontFamily: 'Naomi-Blake-Script',
        padding: 20
    }
}