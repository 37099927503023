import { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../../services/api'
import { AppContext } from '../../../contexts/AppContext';
import { Text, Grid, Button, Paper, TextField, Switch, Divider, Avatar, InnerLoading, CheckboxGroupDialog } from '../../core'
import { SeccionHeader, ConfirmDialog, Upload, AlertDialog } from '../../patterns';

export default function UserEdit() {

    const {setLoading} = useContext(AppContext);

    const [user, setUser] = useState({
        name: '',
        email: '',
        permissions: [],
        active: false,
        speaker: false,
        city: '',
        state: '',
        country: '',
        phone: {
            number: '',
            ddi: ''
        },
        avatar: null
    });

    const [alert, setAlert] = useState({
        show: false,
        errors: [{ message: 'Ocorreu um erro' }]
    })

    let { id } = useParams();

    useEffect(() => {
        if (id !== 'new'){
        setLoading(true);
            api.get(`/admin/users/${id}`).then(res => {
                console.log(res);
                setLoading(false);
                setUser(res.data.user)
            }).catch(err => {
                setLoading(false);
                setAlert({ errors: err.response.data.errors, show: true })
            })}
    }, [id])

    const [rawPermissionsGroup, setRawPermissionGroups] = useState([])
    useEffect(() => {
        if (rawPermissionsGroup.length === 0) {
            api.get('/admin/permissions').then(res => setRawPermissionGroups(res.data.permissions.groups))
        }
    }, [rawPermissionsGroup]);

    const [permissionGroups, setPermissionGroups] = useState([])

    useEffect(() => {
        setPermissionGroups(
            rawPermissionsGroup.map(group => {
                const options = group.permissions.map(permission => {
                    return {
                        label: permission.description,
                        value: permission.id,
                        checked: user.permissions.includes(permission.id),
                    }
                })

                return { name: group.name, options }
            })
        )
    }, [user, rawPermissionsGroup]);

    const [userPermissionsDescriptions, setUserPermissionDescriptions] = useState('')
    useEffect(() => {
        const newUserPermissionDescriptions = []
        permissionGroups.forEach(group => {
            group.options.forEach(permission => {
                if (permission.checked) newUserPermissionDescriptions.push(permission.label)
            })
        })
        setUserPermissionDescriptions(newUserPermissionDescriptions.join(', '))
    }, [permissionGroups])

    const [state, setState] = useState({
        showConfirmDialog: false,
        fileLoading: false,
        showPermissionDialog: false
    })
    const history = useHistory();

    const handleChange = (event) => {
        const { name, value } = event.target

        setUser({
            ...user,
            [name]: value
        })
    }

    const handlePhoneChange = (event) => {
        const { name, value } = event.target
        user.phone[name] = value
        setUser({
            ...user
        })
    }

    const handleDeleteButton = () => {
        setLoading(true)
        api.delete(`/admin/users/${user.id}`).then(res => {
            setLoading(false);
            history.push(`/app/user`)
        }).catch(err => {
            setLoading(false);
            setAlert({ errors: err.response.data.errors, show: true })
        })
    }

    const handleActiveChange = (event) => {
        setUser({
            ...user,
            active: !user.active
        })
    }

    const handleSpeakerChange = (event) => {
        setUser({
            ...user,
            speaker: !user.speaker
        })
    }

    const handleSaveButton = () => {
        setLoading(true);
        if (user.hasOwnProperty('id'))
            api.patch(`/admin/users/${user.id}`, { user } ).then(res => {
                setLoading(false);
                history.push(`/app/user/${id}`)
            }).catch(err => {
                setLoading(false);
                setAlert({ errors: err.response.data.errors, show: true })
            })

        else
            api.post(`/admin/users`, { user }).then(res => {
                setLoading(false)
                const { id } = res.data.user

                history.push(`/app/user/${id}`)
            }).catch(err => {
                setLoading(false)
                setAlert({ errors: err.response.data.errors, show: true })
            })
    }

    const handleUploadFile = (file) => {
        
        if ( file[0] ) {
            setLoading(true);
            const dataFormFile = new FormData()
            dataFormFile.append('file', file[0])

            api.post(`/upload`, dataFormFile).then(res => {
                setLoading(false)
                const { url } = res.data

                setUser({
                    ...user,
                    avatar: url
                })

                setState({
                    ...state, fileLoading: false
                })
            }).catch(err =>{
                setLoading(false);
                setAlert({ errors: err.response.data.errors, show: true })
        })
        }
    }

    const openPermissionsModal = (event) => {
        event.preventDefault()
        setState({ ...state, showPermissionDialog: true })
    }

    const handleClosePermissionsDialog = (newValue) => {
        setState({ ...state, showPermissionDialog: false })

        if (newValue) {
            setUser({ ...user, permissions: newValue})
        }
    };

    return (
        <>
            <Grid container >
                <SeccionHeader title={id ? user.name || user.email : "Novo usuário"}
                    deleteButtonAction={() => setState({ ...state, showConfirmDialog: true })} deleteButton={user.hasOwnProperty('id')}
                />
                <AlertDialog open={alert.show} handleClose={() => setAlert({ ...alert, show: false })} errors={alert.errors} />
                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <Grid container>

                                    <Grid item xs={12}>

                                        <TextField
                                            label="Nome"
                                            variant="outlined"
                                            refs="name"
                                            value={user.name}
                                            fullWidth
                                            name='name'
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="E-mail"
                                            variant="outlined"
                                            refs="email"
                                            value={user.email}
                                            fullWidth
                                            name='email'
                                            style={{  marginTop: 20 }}
                                            onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Cidade"
                                            variant="outlined"
                                            refs="city"
                                            value={user.city}
                                            name='city'
                                            style={{ marginRight: 20, marginTop: 20, marginBottom: 10 }}
                                            onChange={handleChange} />
                                        <TextField
                                            label="Estado"
                                            variant="outlined"
                                            refs="state"
                                            value={user.state}
                                            name='state'
                                            style={{ marginRight: 20, marginTop: 20, marginBottom: 10, width: 250 }}
                                            onChange={handleChange} />
                                        <TextField
                                            label="País"
                                            variant="outlined"
                                            refs="country"
                                            value={user.country}
                                            name='country'
                                            style={{ marginRight: 20, marginTop: 20, marginBottom: 10 }}
                                            onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="DDI"
                                            variant="outlined"
                                            refs="ddi"
                                            value={user.phone.ddi}
                                            name='ddi'
                                            style={{ marginRight: 20, marginTop: 10, marginBottom: 10, width: 60 }}
                                            onChange={handlePhoneChange} />
                                        <TextField
                                            label="Telefone"
                                            variant="outlined"
                                            refs="number"
                                            value={user.phone.number}
                                            name='number'
                                            style={{ marginRight: 20, marginTop: 10, marginBottom: 10, width: 150 }}
                                            onChange={handlePhoneChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label='Permissões' variant='outlined' value={userPermissionsDescriptions} refs="permissions" name='permissions_descriptions'
                                            style={{ marginRight: 20, marginTop: 10, marginBottom: 10 }} fullWidth
                                            onClick={openPermissionsModal} InputProps={{ readOnly: true }}/>

                                        <CheckboxGroupDialog
                                            value={user.permissions}
                                            title="Permissões"
                                            open={state.showPermissionDialog}
                                            onClose={handleClosePermissionsDialog}
                                            groups={permissionGroups}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Switch
                                                checked={user.active}
                                                onChange={handleActiveChange}
                                                name="active"
                                                color="primary"
                                            />
                                            <Text style={{ marginLeft: 5 }}>Ativo</Text>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Switch
                                                checked={user.speaker}
                                                onChange={handleSpeakerChange}
                                                name="speaker"
                                                color="primary"
                                            />
                                            <Text style={{ marginLeft: 5 }}>Pregador</Text>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ display: 'flex', flex: 1, maxWidth: 250, paddingLeft: 20, justifyContent: 'center' }}>
                                {!user.avatar &&
                                    <div style={{position: 'relative', maxHeight: 250}}>
                                        {state.fileLoading && <InnerLoading />}
                                        <Upload
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"Arraste e solte seu arquivo ou clique aqui"}
                                            onChange={handleUploadFile}
                                            showPreviewsInDropzone={false}
                                            filesLimit={1}
                                            onDrop={()=>setState({...state, fileLoading: true})}
                                        />
                                    </div>
                                }
                                {user.avatar &&
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Avatar alt={user.name} src={user.avatar} style={{ width: 200, height: 200 }} />
                                        <Button
                                            style={{ marginTop: 20 }}
                                            variant='outlined'
                                            color='secondary'
                                            onClick={() => setUser({ ...user, avatar: null })}
                                        >Remover Foto</Button>
                                    </div>
                                }
                            </div>
                        </div>


                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'flex-end' }}>
                            <Button color='secondary' variant='outlined' onClick={() => history.push(`/app/user/${id}`)} style={{ marginRight: 20 }}>Cancelar</Button>
                            <Button color='primary' variant='contained' onClick={handleSaveButton}>Salvar</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            {state.showConfirmDialog &&
                <ConfirmDialog
                    open={state.showConfirmDialog}
                    title='Usuários'
                    question={`Deseja realmente excluir o usuário '${user.name}'?`}
                    handleConfirm={handleDeleteButton}
                    handleClose={() => setState({ ...state, showConfirmDialog: false })}
                    cancelButton />
            }
        </>
    )
}
