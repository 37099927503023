import { useState } from 'react'
import { Div, Text } from '../../core'
import { ContentToolbar } from './ContentToolbar'
import { ContentText,
ContentImage,
ContentQuestion,
ContentQuotation,
ContentVerse,
ContentVideo,
ContentList,
ContentTable,
TextEdit,
VideoEdit,VerseEdit, QuotationEdit, ContentHeader, HeaderEdit, ImageEdit, QuestionEdit, ListEdit, TableEdit } from '../'

export const ContentWrapper = ({ content, index, handleDeleteContent, handleEditContent,handleMoveUpward, handleMoveDownward }) => {

    const [showToolbar, setShowToolbar] = useState(false)
    
    const [state, setState] = useState({
        text: false,
        video: false,
        image: false,
        question: false,
        verse: false,
        quotation: false,
        header: false,
        list: false,
        table: false
    })

    const handleSaveContent = (value) => {
        handleEditContent({...content, value})
    }

    const handleEdit = (contentType) => {
        setState({
            ...state,
            [contentType]: true
        })
    }

    const handleClose = (contentType) => {
        setState({
            ...state,
            [contentType]: false
        })
    }

    return (
        <>
            <Div direction='column' onMouseOver={() => setShowToolbar(true)} onMouseLeave={() => setShowToolbar(false)} >
                {showToolbar && <ContentToolbar handleDelete={() => handleDeleteContent(content.id)} handleEdit={()=>handleEdit(content.type)} handleMoveUpward={()=>handleMoveUpward(content)} handleMoveDownward={()=>handleMoveDownward(content)} />}
                {content.type == 'text' && <ContentText index={index} content={content} handleDeleteContent={handleDeleteContent} />}
                {content.type == 'video' && <ContentVideo index={index} content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'verse' && <ContentVerse index={index} content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'quotation' && <ContentQuotation index={index} content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'image' && <ContentImage index={index} content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'question' && <ContentQuestion index={index} content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'header' && <ContentHeader content={content} handleDeleteContent={handleDeleteContent}/>}
                {content.type == 'list' && <ContentList index={index} content={content} handleDeleteContent={handleDeleteContent} />}
                {content.type == 'table' && <ContentTable index={index} content={content} handleDeleteContent={handleDeleteContent} />}
            </Div>
            {state.text && <TextEdit handleClose={()=>handleClose('text')} handleSave={handleSaveContent} content={content}/>}
            {state.video && <VideoEdit handleClose={()=>handleClose('video')} handleSave={handleSaveContent} content={content}/>}
            {state.verse && <VerseEdit handleClose={()=>handleClose('verse')} handleSave={handleSaveContent} content={content}/>}
            {state.quotation && <QuotationEdit handleClose={()=>handleClose('quotation')} handleSave={handleSaveContent} content={content}/>}
            {state.image && <ImageEdit handleClose={()=>handleClose('image')} handleSave={handleSaveContent} content={content}/>}
            {state.question && <QuestionEdit handleClose={()=>handleClose('question')} handleSave={handleSaveContent} content={content}/>}
            {state.header && <HeaderEdit handleClose={()=>handleClose('header')} handleSave={handleSaveContent} content={content}/>}
            {state.list && <ListEdit handleClose={()=>handleClose('list')} handleSave={handleSaveContent} content={content}/>}
            {state.table && <TableEdit handleClose={()=>handleClose('table')} handleSave={handleSaveContent} content={content}/>}
        </>
    )
}

const styles = {

}