import { Div, IconButton, Text } from "../../core";
import { EditIcon, DeleteIcon, ArrowUpwardIcon, ArrowDownwardIcon } from "../../core/Icons";

export const ContentToolbar = ({handleDelete, handleEdit, handleMoveUpward, handleMoveDownward}) => (
    
    <Div justify='flex-end'>
        <IconButton size="small" onClick={handleMoveUpward}>
            <ArrowUpwardIcon />
        </IconButton>
        <IconButton size="small" onClick={handleMoveDownward}>
            <ArrowDownwardIcon />
        </IconButton>
        <IconButton size="small" onClick={handleEdit}>
            <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete} size="small">
            <DeleteIcon/>
        </IconButton>
    </Div>
    
)