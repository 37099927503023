import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";
import { Grid, Paper, Button, Text, ButtonGroup} from "../../core";
import { SeccionHeader, NewLessonStepButton, ContentWrapper } from "../../patterns";
import { AppContext } from "../../../contexts/AppContext";
import { Box } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function LessonStudio() {

   let { id } = useParams();
   const history = useHistory();
   const { setLoading } = useContext(AppContext)

   const [lesson, setLesson] = useState({
      lessonSteps: []
   })

   const [language, setLanguage] = useState('pt')

   const [selectedStep, setSelectedStep] = useState(0)

   useEffect(() => {
      loadLesson()
   }, [])

   const loadLesson = () => {
      setLoading(true)
      api.get(`admin/lessons/${id}?language=${language}`).then(response => {
         const { lesson } = response.data

         setLesson(lesson)
         setLoading(false)
      })
   }

   const saveLesson = (lesson) => {
      setLoading(true)
      api.patch(`admin/lessons/${id}`, {
         lesson: {
            ...lesson,
            lessonSteps: lesson.lessonSteps.map(step => step.id)
         }
      }).then(response => {
         setLoading(false)
      })
   }


   const handleAddLessonStep = () => {
      setLoading(true)
      api.post('admin/steps', {}).then(response => {
         const { step } = response.data


         const steps = lesson.lessonSteps
         const splicedSteps = steps.splice(selectedStep + 1, Number.MAX_VALUE, step)
         const updatedLessonSteps = steps.concat(splicedSteps)

         const updatedLesson = {
            ...lesson,
            lessonSteps: updatedLessonSteps
         }
         setLoading(false)
         setLesson(updatedLesson)
         saveLesson(updatedLesson)
         setSelectedStep(selectedStep + 1)
      })
   }

   const handleNextStep = () => {
      if (selectedStep + 1 != lesson.lessonSteps.length) {
         setSelectedStep(selectedStep + 1)
      }
   }

   const handlePreviousStep = () => {
      if (selectedStep != 0) {
         setSelectedStep(selectedStep - 1)
      }
   }

   const handleDeleteStep = (selectedStep) => {
      const updatedLesson = {
         ...lesson,
         lessonSteps: lesson.lessonSteps.filter(step => step.id != lesson.lessonSteps[selectedStep].id)
      }

      setLesson(updatedLesson)
      saveLesson(updatedLesson)
      selectedStep === 0 ? setSelectedStep(selectedStep + 1) : setSelectedStep(selectedStep - 1)
   }


   const handleCreateContent = ({ contentType, stepId }) => {
      api.post(`/admin/lessons/${id}/steps/${stepId}/contents`, {
         content: {
            type: contentType.toLowerCase(),
            language: language
         }
      }).then(response => {
         console.log(response.data.step)
         loadLesson()   
      console.table(lesson.lessonSteps[selectedStep].contents)
      })
   }
   

   const handleChangeLanguage = (selectedLanguage) => {
      setLanguage(selectedLanguage)
   }

   return (
      <>
         <Grid container style={{ height: "100%", display: "flex", flexDirection: "column" }} >
            <SeccionHeader
               title={`${lesson?.title} - Passos da Lição`}
               newButton={true}
               deleteButton={true}
               deleteButtonAction={() => handleDeleteStep(selectedStep)}
               newButtonAction={handleAddLessonStep}
            />
            <Paper style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 40, marginBottom: '25px' }}>
               <Text style={{ fontSize: '17px', fontWeight: '600', marginBottom: '20px' }}>{`Passo ${selectedStep + 1} / ${lesson.lessonSteps.length}`}</Text>
               <ButtonGroup color="white" size="small" variant="contained">
                  <Button color={language === 'pt' ? "primary" : 'iherit'} variant={language === 'pt' ? "contained" : 'iherit'} onClick={() => handleChangeLanguage('pt')}>PT</Button>
                  <Button color={language === 'en' ? "primary" : 'iherit'} variant={language === 'en' ? "contained" : 'iherit'} onClick={() => handleChangeLanguage('en')}>EN</Button>
                  <Button color={language === 'es' ? "primary" : 'iherit'} variant={language === 'es' ? "contained" : 'iherit'} onClick={() => handleChangeLanguage('es')}>ES</Button>
               </ButtonGroup>
               <NewLessonStepButton handleSelect={(contentType) => handleCreateContent({ contentType, stepId: lesson.lessonSteps[selectedStep].id })} />
               <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box style={{ display: 'flex', width: '80px', height: '50px', justifyContent: 'flex-start', alignItems: 'center' }}>
                     <Button onClick={handlePreviousStep}><ArrowBackIcon fontSize="large" /></Button>
                  </Box>
                  <Box style={{ height: 667, width: 375, backgroundColor: '#000', borderRadius: 20, padding: 20, marginTop: '30px' }}>
                     <Box style={{ backgroundColor: '#FFF', height: '100%', overflowY: 'scroll', borderRadius: 10 }}>
                        <StepItem
                           lessonStep={lesson.lessonSteps[selectedStep]}
                           selectedStep={selectedStep}
                           lesson={lesson}
                           setLesson={setLesson}
                           saveLesson={saveLesson}
                           loadLesson={loadLesson}
                           language={language}
                           handleCreateContent={handleCreateContent}
                        />
                     </Box>
                  </Box>
                  <Box style={{ display: 'flex', width: '80px', height: '50px', justifyContent: 'flex-end', alignItems: 'center' }}>
                     <Button onClick={handleNextStep}><ArrowForwardIcon fontSize="large" /></Button>
                  </Box>
               </Box>
            </Paper>
         </Grid>
      </>
   );
}

const StepItem = ({ lessonStep, lesson, loadLesson, setLesson, saveLesson, selectedStep, language}) => {

   let { id } = useParams();
   const filteredContents = lessonStep?.contents?.filter(content => content.language === language)

   const handleDeleteContent = (contentId) => {
      api.delete(`/admin/lessons/${id}/steps/${lessonStep.id}/contents/${contentId}`).then(response => {
         loadLesson()
      })
   }

   const handleEditContent = (content) => {
      api.patch(`/admin/lessons/${id}/steps/${lessonStep.id}/contents/${content.id}`, { content }).then(response => {
         loadLesson()
      })
   }

   const handleMoveUpward = (content) => {
      
      
      if (filteredContents.indexOf(content) === 0 ) return
      const contents = lessonStep.contents

      const splicedContents = contents.splice(contents.indexOf(content) -1, Number.MAX_VALUE, content).filter(function(element) {return element !== content})
      const updatedContents = contents.concat(splicedContents)

      api.put(`/admin/lessons/steps/${lessonStep.id}`, { updatedContents }).then(response => {
         loadLesson()
      })
   }

   const handleMoveDownward = (content) => {
      
      if (filteredContents.indexOf(content) === filteredContents.length -1) return
      const contents = lessonStep.contents

      const splicedContent = contents.splice(contents.indexOf(content) +1, 1, content)
      contents.splice(contents.indexOf(content), 1, splicedContent[0])

      api.put(`/admin/lessons/steps/${lessonStep.id}`, { updatedContents: contents }).then(response => {
         loadLesson()
      })
   }

      
   return (
      <>
         {filteredContents?.map((content, index) =>
            <ContentWrapper index={index} content={content} handleDeleteContent={handleDeleteContent} handleEditContent={handleEditContent} handleMoveUpward={handleMoveUpward} handleMoveDownward={handleMoveDownward} />
         )}
      </>
   )
}

const styles = {
   paperContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      width: "100%",
      flex: 1
   }
}