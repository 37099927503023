import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import api from '../../../services/api'
import { AppContext } from '../../../contexts/AppContext';
import { Text, Grid } from '../../core'
import { Paper } from '../../core'
import { booleanToString } from '../../../helpers/utils'
import { LineDetails, SeccionHeader, ConfirmDialog, AlertDialog } from '../../patterns';

export default function UserDetails() {

    const {setLoading} = useContext(AppContext)

    const [state, setState] = useState({
        showConfirmDialog: false
    });

    const [alert, setAlert] = useState({
        show: false,
        errors: [{ message: 'Ocorreu um erro' }]
    })

    let { id } = useParams();
    const [user, setUser] = useState({});
    const [userLastToken, setUserLastToken] = useState({})

    useEffect(() => {
        setLoading(true);
        api.get(`/admin/users/${id}`).then(res => {
            setLoading(false);
            setUser(res.data.user)
        }).catch(err => {
            setLoading(false);
            setAlert({ errors: err.response.data.errors, show: true })
        })
    }, [id]);

    useEffect(() => {
        setLoading(true);
        api.get(`/admin/user/lastToken/${id}`).then(res => {
            setLoading(false)
            const {code, createdAt } = res.data
            setUserLastToken({code, createdAt})
        }).catch(err => {
            setLoading(false);
            setAlert({ errors: err.response.data.errors, show: true })
        })
    }, [id]);

    const [userPermissionsDescriptions, setUserPermissionDescriptions] = useState('')
    useEffect(() => {
        if (user.permissions && user.permissions.length > 0) {
            api.get('/admin/permissions').then(res => {
                const newUserPermissionDescriptions = []
                res.data.permissions.groups.forEach(group => {
                    group.permissions.forEach(permission => {
                        if(user.permissions.includes(permission.id)) newUserPermissionDescriptions.push(permission.description)
                    })
                })
                setUserPermissionDescriptions(newUserPermissionDescriptions.join(', '))
            }).catch(err => {
                setAlert({ errors: err.response.data.errors, show: true })
            })
        }
    }, [user])

    const { phone = {} } = user
    const { ddi, number } = phone

    const handleDeleteButton = () => {
        setLoading(true)
        api.delete(`/admin/users/${user.id}`).then(res => {
            setLoading(false)
            history.push(`/app/user`)
        }).catch(err => {
            setLoading(false)
            setAlert({ errors: err.response.data.errors, show: true })
        })
    }

    const history = useHistory();
    const handleEditButton = useCallback((_) => history.push(`/app/user/edit/${id}`), [history, id]);

    return (
        <>
            <Grid container >
                <SeccionHeader title={user.name || user.email } editButton editButtonAction={() => handleEditButton(user.id)} deleteButton deleteButtonAction={() => setState({...state, showConfirmDialog: true})} />
                <AlertDialog open={alert.show} handleClose={() => setAlert({ ...alert, show: false })} errors={alert.errors} />
                <Grid item xs={12}>
                    <Paper style={{ flex: 1, display: 'flex', padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Text variant='h6'>Dados do Usuário</Text>
                            </Grid>
                            <LineDetails title='Nome' text={user.name} />
                            <LineDetails title='E-mail' text={user.email} />
                            <LineDetails title='Data de Criação' text={user.createdAt} />
                            <LineDetails title='Ativo' text={booleanToString(user.active)} />
                            <LineDetails title='Pregador' text={booleanToString(!!user.speaker)} />
                            <LineDetails title='Permissões' text={userPermissionsDescriptions} />
                            <LineDetails title='Último token de Acesso' text={userLastToken?.code || 'Sem Acesso'} />
                            <LineDetails title='Último token de Acesso utilizado?' text={userLastToken && user ? booleanToString(userLastToken?.createdAt < user.lastAccessAt) : 'Sem acesso'} />
                            <LineDetails title='Último acesso em' text={user.lastAccessAt} />
                            <LineDetails title='Última versão do app' text={user.lastAppVersion} />
                            <LineDetails title='Quantidade de acessos' text={user.accessCount} />
                            {user.hasOwnProperty('city') && <LineDetails title='Localização' text={`${user.city} - ${user.state} | ${user.country}`} />}
                            {user.hasOwnProperty('phone') && <LineDetails title='Telefone' text={`${ddi} ${number}`} />}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {state.showConfirmDialog &&
                <ConfirmDialog
                    open={state.showConfirmDialog}
                    title='Usuários'
                    question={`Deseja realmente excluir o usuário '${user.name}'?`}
                    handleConfirm={handleDeleteButton}
                    handleClose={() => setState({...state, showConfirmDialog: false})}
                    cancelButton/>
            }
        </>
    )
}
