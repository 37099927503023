import { FormControl, InputLabel, OutlinedInput } from '../core'
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export const DurationField = (props) => {
    const { handleChange, value, name } = props

    function TextMaskCustom(props) {
        const { inputRef, ...other } = props;
      
        return (
          <MaskedInput      
            {...other}
            ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/,/[0-9]/,':',/[0-5]/,/[0-9]/,':', /[0-5]/,/[0-9]/]}
            // placeholderChar={'\u2000'}
            showMask
          />
        );
      }
    
      TextMaskCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
      };

      return (
          <FormControl>
                    <InputLabel shrink='true' variant='outlined'>Duração</InputLabel>
                        <OutlinedInput
                            label="Duração"
                            name={name}
                            value={value}
                            onChange={handleChange}
                            inputComponent={TextMaskCustom}
                            style={{maxWidth: 150}}
                        />
                </FormControl>
      )

}


