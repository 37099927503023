import { useState } from "react";
import { SpeedDialIcon, SpeedDialAction, SpeedDial } from "../../core";
import {
  SubjectIcon,
  OndemandVideoIcon,
  FormatQuoteIcon,
  MenuBookIcon,
  LiveHelpIcon,
  ImageIcon,
  ListIcon,
  TableIcon,
  HeaderIcon,
} from "../../core/Icons";

export const NewLessonStepButton = ({ handleSelect }) => {

  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon"
      icon={<SpeedDialIcon />}
      open={true}
      hidden={true}
      direction={`left`}
      style={{ marginTop: 10, padding: 0 }}

    >
      <SpeedDialAction
        key={0}
        icon={<SubjectIcon />}
        tooltipTitle={`Texto`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Text")}
        TooltipClasses={{ marginBottom: 20 }}
      />
      <SpeedDialAction
        key={1}
        icon={<OndemandVideoIcon />}
        tooltipTitle={`Vídeo`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Video")}
      />
      <SpeedDialAction
        key={2}
        icon={<FormatQuoteIcon />}
        tooltipTitle={`Aspas`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Quotation")}
      />
      <SpeedDialAction
        key={3}
        icon={<MenuBookIcon />}
        tooltipTitle={`Citação Bíblica`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Verse")}
      />
      <SpeedDialAction
        key={3}
        icon={<ImageIcon />}
        tooltipTitle={`Imagem`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Image")}
      />
      <SpeedDialAction
        key={3}
        icon={<LiveHelpIcon />}
        tooltipTitle={`Pergunta`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Question")}
      />
      <SpeedDialAction
        key={3}
        icon={<HeaderIcon />}
        tooltipTitle={`Header`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Header")}
      />
      <SpeedDialAction
        key={3}
        icon={<ListIcon />}
        tooltipTitle={`Lista`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("List")}
      />
      <SpeedDialAction
        key={3}
        icon={<TableIcon />}
        tooltipTitle={`Tabela`}
        tooltipPlacement="top-start"
        onClick={() => handleSelect("Table")}
      />
    </SpeedDial>
  );
};
