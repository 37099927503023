import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Radio,
  Div,
  Text,
  IconButton,
  Switch,
  Spacer,
} from "../../core";
import { AddIcon, DeleteIcon } from "../../core/Icons";
import { Question } from "./Question";

export const QuestionEdit = ({
  content: oldContent,
  handleClose,
  handleSave,
}) => {
  const { value = {} } = oldContent;

  const [content, setContent] = useState(value);
  let { question, answer, multipleChoice = false } = content;
  const initalValue = content;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const [checked, setChecked] = useState(multipleChoice);

  const handleQuestionType = () => {
    if (checked === false) content.answer = [{ answer: "", isCorrect: true }];
    content.multipleChoice = !multipleChoice;
    setChecked(!checked);
  };

  const handleCorrectAnswer = (index, {target}) => {
    const { name } = target
    
    const newAnswers = [...answer]
    newAnswers.forEach(answer => {if(answer.isCorrect === true) (answer.isCorrect = false)})
    newAnswers[index][name] = !newAnswers[index][name]
    setContent({
      ...content,
      answer: newAnswers
    })
  };
  const handleChangeInput = (index, {target}) => {
    const { name, value } = target
    const newAnswers = [...answer]
    newAnswers[index][name] = value
    setContent({
      ...content,
      answer: newAnswers
    })

  };
  // console.log(answer);

  const handleAddField = () => {
    if (answer.length > 4) return false;

    const newOption = {
      answer: "",
      isCorrect: false,
    };

    setContent({ ...content, answer: [...content.answer, newOption] });
  };

  const handleRemoveField = (index) => {
    const newAnswers = [...answer];
    newAnswers.splice(index, 1);
    setContent({ ...content, answer: newAnswers });
  };

  return (
    <Dialog open={true} fullWidth>
      <Div>
        <DialogTitle>Edição da Pergunta</DialogTitle>
        <Spacer />
        <Div style={{ marginBottom: 20, marginRight: 10 }}>
          <Text>Dissertativa</Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Switch
              checked={checked}
              onChange={handleQuestionType}
              name="multipleChoice"
              color="primary"
            />
          </div>
          <Text>Objetiva</Text>
        </Div>
      </Div>
      {!multipleChoice && (
        <DialogContent>
          <TextField
            label="Pegunta"
            value={question}
            onChange={handleChange}
            fullWidth
            name="question"
          />
          <TextField
            label="Resposta"
            minRows={3}
            multiline
            onChange={handleChange}
            value={answer}
            style={{ marginTop: 10 }}
            fullWidth
            name="answer"
          />
        </DialogContent>
      )}
      {multipleChoice && (
        <>
          <DialogContent>
            <TextField
              label="Pegunta"
              value={question}
              onChange={handleChange}
              fullWidth
              name="question"
            />
          </DialogContent>
          {answer.map((field, index) => (
            <div style={{ marginLeft: 10, marginTop: 5, marginRight: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <>
                  <Radio
                    checked={answer[index].isCorrect}
                    onChange={(event) => handleCorrectAnswer(index, event)}
                    value={`question-option${index}`}
                    name="isCorrect"
                    style={{ marginRight: 10 }}
                  />
                  <TextField
                    size="small"
                    label={`Resposta`}
                    value={answer[index].answer}
                    onChange={(event) => handleChangeInput(index, event)}
                    fullWidth
                    multiline
                    maxRows={3}
                    name="answer"
                  />
                  {answer.length >= 1 && (
                    <IconButton onClick={() => handleRemoveField(index)}>
                      <DeleteIcon style={{ color: 'lightgray' }} />
                    </IconButton>
                  )}
                </>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", lexDirection: "row" }}>
            <IconButton onClick={handleAddField}>
              <AddIcon color="primary" />
            </IconButton>
            <Text style={{ marginTop: 15 }}>Adicionar Resposta</Text>
          </div>
        </>
      )}

      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setContent(initalValue);
            handleClose();
            console.log("sim");
          }}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave(content);
            handleClose();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
