import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api'
import { Paper, IconButton, Text, Table, TableBody, TableCell, TranslationCell, TableContainer, TableRow, Select, MenuItem, Div, Spacer, TextField, Divider } from '../../core'
import { SecctionContainer, SeccionHeader, AlertDialog } from '../../patterns'
import { EditIcon, ViewListIcon } from '../../core/Icons';
import { AppContext } from '../../../contexts/AppContext'
import { LessonPaginator } from '../../patterns/lesson/LessonPaginator';
import { getResizedImageUrl } from '../../../helpers/image';
import { PodcastCell } from '../../patterns/lesson/PodcastCell';
import { VideoCell } from '../../patterns/lesson/VideoCell';

export default function LessonsList() {

   const { setLoading } = useContext(AppContext)
   const [lessons, setLessons] = useState([]);
   const [query, setQuery] = useState('')
   const [firstLoad, setFirstLoad] = useState(true)
   const [pagination, setPagination] = useState({
      page: 1,
      per_page: 10,
      count: 0,
      sort: 'asc',
      status: null, 
      sortField: 'number',

   });

   const [alert, setAlert] = useState({
      show: false,
      errors: [{ message: 'Ocorreu um erro' }]
   })

   useEffect(async () => {
      let localPagination = await window.localStorage.getItem('pagination');

      if (localPagination) {
         localPagination = JSON.parse(localPagination)
         setPagination(localPagination);
         loadLessons({ localPagination });
      }

      if (!localPagination) {
         loadLessons({ localPagination: pagination });
      }

      setFirstLoad(false)

   }, []);

   useEffect(() => {
      if (!firstLoad) {
         window.localStorage.setItem('pagination', JSON.stringify(pagination));
         loadLessons({ localPagination: pagination });
      }
   }, [pagination.page, pagination.per_page, pagination.sort, pagination.status]);

   const loadLessons = ({ localPagination = pagination }) => {

      setLoading(true)

      api.get(`/admin/lessons?page=${localPagination.page}&per_page=${localPagination.per_page}&sort=${localPagination.sortField}:${localPagination.sort}&q=youtubeChannel:eq:Fundamentos,title:like:${query}||number:eq:${query},${localPagination.status ? `active:eq:${localPagination.status}` : ''}`)
         .then(res => {
            setPagination({ ...localPagination, count: res.data.pagination.count })
            setLoading(false)
            setLessons(res.data.lessons)

         }).catch(err => {
            setLoading(false)
            setAlert({ errors: err.response, show: true })
         })
   }

   const history = useHistory();

   const handleEdit = useCallback((id) => history.push(`/app/lessons/edit/${id}`), [history]);
   const handleEnterStudio = useCallback((id) => history.push(`/app/lessons/${id}/studio`), [history]);
   const handleNewLesson = useCallback(() => history.push(`/app/lessons/edit/new`), [history]);
   const handleLessonDetails = useCallback((id) => history.push(`/app/lessons/${id}`), [history]);

   const onPerPageChange = ({ target }) => {
      setPagination({ ...pagination, per_page: target.value, page: 1 })
   }
   const onPageChange = (event, newPage) => {
      setPagination({ ...pagination, page: newPage + 1 })
   }

   const handleSortChange = ({ target }) => {
      setPagination({
         ...pagination,
         sort: target.value
      })
   }

   const handleStatusChange = ({ target }) => {
      setPagination({
         ...pagination,
         status: target.value
      })
   }

   const handleQueryChange = ({ target }) => {
      const { value } = target
      setQuery(value)
   }
   
   const calculatePercentage = (lesson) => {
      let completedItems = 0
      let lessonLength = 0
      Object.entries(lesson).forEach(function ([key, value]) {
         if (key !== 'createdAt' && key !== 'id' && key !== 'private' && key !== 'number' && !!value) {
            completedItems++
         }
         if (key !== 'createdAt' && key !== 'id' && key !== 'private' && key !== 'number') {
            lessonLength++
         }
      })
      return (`${Math.floor(completedItems / lessonLength * 100)}%`)
   }

   const supportedTranslations = (lesson) => {
      const supportedKeys = [];

      if (lesson.title_pt && lesson.description_pt) supportedKeys.push('pt');
      if (lesson.title_en && lesson.description_en) supportedKeys.push('en');
      if (lesson.title_es && lesson.description_es) supportedKeys.push('es');
      return supportedKeys;
   };

   const supportedPdfTranslations = (lesson) => {
      const supportedKeys = [];         

      if (lesson.printVersion_pt) supportedKeys.push('pt');
      if (lesson.printVersion_en) supportedKeys.push('en');
      if (lesson.printVersion_es) supportedKeys.push('es');
      return supportedKeys;
};
   
   return (
      <SecctionContainer>
         <SeccionHeader title='Ensinos' newButtonAction={handleNewLesson} newButton />
         <AlertDialog open={alert.show} handleClose={() => setAlert({ ...alert, show: false })} errors={alert.errors} />
         <TableContainer component={Paper}>
            <Div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
               <TextField
                  label="Busca"
                  variant="outlined"
                  refs="queryLesson"
                  value={query}
                  fullWidth
                  autoFocus
                  name='queryLesson'
                  placeholder='Digite o termo que deseja buscar'
                  onChange={handleQueryChange}
                  onKeyPress={({ key }) => { if (key === 'Enter') loadLessons({ localPagination: pagination }) }}
                  style={{ marginTop: 20, marginLeft: 20 }}
               />
               <Spacer />
               <Select
                  id='sort'
                  value={pagination.sort}
                  handleChange={handleSortChange}
                  label='Ordem'
                  name='sort'
                  style={{ marginLeft: 20, marginRight: 20, maxWidth: 200 }}
               >
                  <MenuItem value={'asc'}>Número da Lição (asc)</MenuItem>
                  <MenuItem value={'desc'}>Número da Lição (desc)</MenuItem>
               </Select>
               <Spacer />
               <Select
                  id='status'
                  value={pagination.status}
                  handleChange={handleStatusChange}
                  label='Status'
                  name='status'
                  style={{ marginRight: 20, maxWidth: 200 }}
               >
                  <MenuItem value={null}>Todos</MenuItem>
                  <MenuItem value={true}>Ativo</MenuItem>
                  <MenuItem value={false}>Inativo</MenuItem>
               </Select>
            </Div>
            <LessonPaginator pagination={pagination} onPageChange={onPageChange} onPerPageChange={onPerPageChange} />
            <Divider />
            <Table size="medium">
               <TableBody>
                  {lessons.map((lesson) => (
                     <TableRow key={lesson.id} hover>
                        <TableCell width={280} onClick={() => handleLessonDetails(lesson.id)} style={{ cursor: 'pointer' }}>
                           <div style={{ display: 'flex', flexDirection: 'lesson' }}>
                              <div style={{ backgroundColor: 'gray', width: 120, height: 68 }}>
                                 <img alt={lesson.name} src={getResizedImageUrl({ url: lesson.imgThumb, width: 120, height: 68 })} />
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 10 }}>
                                 <Text style={{
                                    fontWeight: 700,
                                    maxWidth: 160,
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                 }}>{lesson.title.toUpperCase()}</Text>
                                 <Text style={{ fontWeight: 700, fontSize: 20, color: '#557316' }}>
                                    {lesson.number && '#'}
                                    {lesson.number ? lesson.number < 10 ? `00${lesson.number}` : lesson.number >= 10 && lesson.number < 100 ? `0${lesson.number}` : lesson.number : ''}
                                 </Text>
                              </div>
                           </div>
                        </TableCell>
                        <TableCell
                           align="left"
                           maxWidth={1000}
                           style={{ padding: 0 }}
                        >
                           <div style={{
                              color: 'gray',
                              display: 'flex',
                              justifyContent: 'flex-end',
                           }}>
                              <TranslationCell
                                 title={'Traduções'}
                                 supportedTranslations={supportedTranslations(lesson)}/>
                              <TranslationCell
                                 title={'Transcrições'}
                                 supportedTranslations={supportedPdfTranslations(lesson)}/>
                              <div style={styles.subTitleContainer}>
                                 <Text style={styles.subTitleText}></Text>
                                 <PodcastCell
                                    title={'Podcast Curto'}
                                    applePodcast={!!lesson.urlApplePodcastShort ? true : false}
                                    deezerPodcast={!!lesson.urlDeezerShort ? true : false}
                                    spotifyPodcast={!!lesson.urlSpotifyShort ? true : false}
                                    googlePodcast={!!lesson.urlGooglePodcastShort ? true : false}
                                 />
                              </div>
                              <div style={styles.subTitleContainer}>
                                 <PodcastCell
                                    title={'Podcast Completo'}
                                    applePodcast={!!lesson.urlApplePodcast ? true : false}
                                    deezerPodcast={!!lesson.urlDeezer ? true : false}
                                    spotifyPodcast={!!lesson.urlSpotify}
                                    googlePodcast={!!lesson.urlGooglePodcast}
                                 />
                              </div>
                              <div style={styles.subTitleContainer}>
                                 <VideoCell
                                    title={'Video Curto'}
                                    urlVideo={!!lesson.urlShortVideo ? true : false}
                                    urlUploadedVideo={!!lesson.urlShortVideoS3 ? true : false}
                                 />
                              </div>
                              <div style={styles.subTitleContainer}>
                                 <VideoCell
                                    title={'Live Completa'}
                                    urlVideo={!!lesson.urlLive ? true : false}
                                    urlUploadedVideo={!!lesson.urlVideoS3 ? true : false} />
                              </div>
                              <div style={styles.subTitleContainer}>
                                 <Text style={styles.subTitleText}>Conclusão</Text>
                                 <Text style={styles.subPercentageText}>
                                    {calculatePercentage(lesson)}</Text>
                              </div>
                           </div>
                        </TableCell>
                        <TableCell flex align="left" width={30} style={{ padding: 0 }}>
                           <IconButton aria-label="Editar" color="primary" onClick={() => handleEdit(lesson.id)}>
                              <EditIcon />
                           </IconButton>
                        </TableCell>
                        <TableCell flex align="left" width={30} style={{ padding: 0 }}>
                           <IconButton aria-label="Studio" color="primary" onClick={() => handleEnterStudio(lesson.id)}>
                              <ViewListIcon />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
            <LessonPaginator pagination={pagination} onPageChange={onPageChange} onPerPageChange={onPerPageChange} />
         </TableContainer>

      </SecctionContainer>
   )
}

const styles = {
   subTitleContainer: {
      borderRight: '1px solid lightgray',
      padding: '0px 10px'
   },
   subTitleText: {
      fontSize: 10,
      whiteSpace: 'nowrap'
   },
   subLanguageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
   },
   subPercentageText: {
      fontWeight: 700,
      color: '#557316',
      fontSize: 20,
      marginTop: 5
   }
}
