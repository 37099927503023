import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import moment from "moment";
import api from "../../../services/api";
import {
  Grid,
  Button,
  Paper,
  TextField,
  Divider,
  MenuItem,
  Select,
  Text,
  InnerLoading,
  Image
} from "../../core";
import { YouTubeIcon, PageviewIcon } from "../../core/Icons";
import {
  SeccionHeader,
  ConfirmDialog,
  Upload,
  PodcastFormFields,
  PodcastShortFormFields,
  AlertDialog,
} from "../../patterns";

export default function LessonEdit() {
  const { setLoading } = useContext(AppContext);

  const [lesson, setLesson] = useState({
    title_pt: "",
    title_en: "",
    title_es: "",
    number: "",
    active: false,
    description_pt: "",
    description_en: "",
    description_es: "",
    speaker: null,
    private: true,
    parent: null,
    urlLive: "",
    taughtBy: null,
    slug: "",
    metaTitle: "",
    metaDescription: "",
    language: "pt-BR",
    imgThumb: null,
    printVersion_pt: null,
    printVersion_en: null,
    printVersion_es: null,
    urlDeezer: "",
    durationDeezer: "",
    urlDeezerShort: "",
    durationDeezerShort: "",
    youtubeChannel: "",
    urlShortVideoS3: "",
    urlVideoS3: "",
    urlShortVideo: "",
    urlSignLanguageVideo: "",
    urlGooglePodcast: "",
    durationGooglePodcast: "",
    urlGooglePodcastShort: "",
    durationGooglePodcastShort: "",
    urlSpotify: "",
    durationSpotify: "",
    urlSpotifyShort: "",
    durationSpotifyShort: "",
    urlApplePodcast: "",
    durationApplePodcast: "",
    urlApplePodcastShort: "",
    durationApplePodcastShort: "",
    publishedAt: moment().format("yyyy-MM-DD"),
  });

  const [users, setUsers] = useState();

  const [state, setState] = useState({
    title: "Ensino",
    showConfirmDialog: false,
    errors: {},
  });

  let { id, parentId } = useParams();

  const history = useHistory();

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });

  useEffect(() => {
    api
      .get(`/admin/speakers`)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.users);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ errors: err.response.data.errors, show: true });
      });
  }, []);

  useEffect(() => {
    if (id !== "new") {
      setLoading(true);
      api
        .get(`/admin/lessons/${id}?lang=es`)
        .then((res) => {
          setLoading(false);
          setLesson(res.data.lesson);
          setState({ ...state, title: res.data.lesson.title });
        })
        .catch((err) => {
          setAlert({ errors: err.response.data.errors, show: true });
          setLoading(false);
          history.push("/app/lessons");
        });
    } else state.title = "Nova lição";
    setState({ ...state });
    lesson.parent = parentId;
    setLesson(lesson);
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target
    console.log(name, value)

    setLesson({
      ...lesson,
      [name]: value,
    });

    delete state.errors[name];
    setState(state);
  };


  const handleDeleteButton = () => {
    api
      .delete(`/admin/lessons/${lesson.id}`)
      .then((res) => {
        history.push(`/app/lessons`);
      })
      .catch((err) => {
        setAlert({ errors: err.response.data.errors, show: true });
      });
  };

  const handleCancelButton = () => {
    if (lesson.hasOwnProperty("id")) history.push(`/app/lessons/${lesson.id}`);
    else history.push("/app/lessons");
  };
  const handleSaveButton = () => {
    let request;

    delete lesson.lessonSteps;
    if (lesson.hasOwnProperty("id"))
      request = api.patch(`/admin/lessons/${lesson.id}`, { lesson });
    else request = api.post(`/admin/lessons`, { lesson });

    request
      .then((res) => {
        history.push(`/app/lessons`);
      })
      .catch((err) => {
        const errors = {};
        // eslint-disable-next-line array-callback-return
        err.response.data.errors.map((error) => {
          const field = error.field.split(".")[1];
          errors[field] = error.message;
          setAlert({ errors: err.response.data.errors, show: true });
        });
        setState({
          ...state,
          errors: errors,
        });
      });
  };

  const handleUploadFile = (event) => {
    const { name, loadedFile } = event;
    if (event.loadedFile[0]) {
      setLoading(true);
      const dataFormFile = new FormData();
      dataFormFile.append("file", event.loadedFile[0]);

      api
        .post(`/upload`, dataFormFile)
        .then((res) => {
          setLoading(false);
          const { url } = res.data;

          setLesson({
            ...lesson,
            [name]: url,
          });

          setState({
            ...state,
            fileLoading: false,
          });
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ errors: err.response.data.errors, show: true });
        });
    }
  };

  return (
    <>
      <Grid container>
        <SeccionHeader
          title={state.title}
          deleteButtonAction={() =>
            setState({ ...state, showConfirmDialog: true })
          }
          deleteButton={lesson.hasOwnProperty("id")}
        />
        <AlertDialog
          open={alert.show}
          handleClose={() => setAlert({ ...alert, show: false })}
          errors={alert.errors}
        />

        <Grid item xs={12}>
          <Paper
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Text variant="h6">
                      Títulos
                    </Text>
                    <TextField
                      label="Título em português"
                      variant="outlined"
                      refs="title_pt"
                      value={lesson.title_pt}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_pt"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                      translateButton={false}
                    />
                    <TextField
                      label="Título em inglês"
                      variant="outlined"
                      refs="title_en"
                      value={lesson.title_en}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_en"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                      translateButton={false}
                    />
                    <TextField
                      label="Título em espanhol"
                      variant="outlined"
                      refs="title_es"
                      value={lesson.title_es}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_es"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                      translateButton={false}
                    />
                    <Text variant="h6">
                      Descrições
                    </Text>
                    <TextField
                      label="Descrição em português"
                      variant="outlined"
                      refs="description_pt"
                      value={lesson.description_pt}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_pt"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <TextField
                      label="Descrição em inglês"
                      variant="outlined"
                      refs="description_en"
                      value={lesson.description_en}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_en"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <TextField
                      label="Descrição em espanhol"
                      variant="outlined"
                      refs="description_es"
                      value={lesson.description_es}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_es"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label="URL Live Youtube"
                        variant="outlined"
                        refs="urlLive"
                        value={lesson.urlLive}
                        fullWidth
                        helperText={state.errors.urlLive}
                        name="urlLive"
                        onChange={handleChange}
                        style={{ marginBottom: 20 }}
                      />
                      <YouTubeIcon
                        style={{
                          height: 50,
                          width: 50,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        color="primary"
                        onClick={() =>
                          window.open(`${lesson.urlLive}`, "_blank").focus()
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label="URL do Vídeo Curto"
                        variant="outlined"
                        refs="urlShortVideo"
                        value={lesson.urlShortVideo}
                        fullWidth
                        helperText={state.errors.urlShortVideo}
                        name="urlShortVideo"
                        onChange={handleChange}
                        style={{ marginBottom: 20 }}
                      />
                      <YouTubeIcon
                        style={{
                          height: 50,
                          width: 50,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        color="primary"
                        onClick={() =>
                          window
                            .open(`${lesson.urlShortVideo}`, "_blank")
                            .focus()
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label="URL do Vídeo em Libras"
                        variant="outlined"
                        refs="urlSignLanguageVideo"
                        value={lesson.urlSignLanguageVideo}
                        fullWidth
                        helperText={state.errors.urlSignLanguageVideo}
                        name="urlSignLanguageVideo"
                        onChange={handleChange}
                        style={{ marginBottom: 20 }}
                      />
                      <YouTubeIcon
                        style={{
                          height: 50,
                          width: 50,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        color="primary"
                        onClick={() =>
                          window
                            .open(`${lesson.urlSignLanguageVideo}`, "_blank")
                            .focus()
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <TextField
                        id="publishedAt"
                        label="Data da Transmissão"
                        type="date"
                        onChange={handleChange}
                        name="publishedAt"
                        value={lesson.publishedAt}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="time"
                        label="Horário"
                        type="time"
                        defaultValue="20:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        inputProps={{
                          step: 1800, // 5 min
                        }}
                        style={{ marginLeft: 10 }}
                      />
                      <TextField
                        label="Número"
                        variant="outlined"
                        refs="number"
                        value={lesson.number}
                        error={state.errors.hasOwnProperty("number")}
                        helperText={state.errors.number}
                        name="number"
                        onChange={handleChange}
                        style={{ marginLeft: 10 }}
                      />
                    </div>
                    <Text variant="h6" style={{ marginTop: 20 }}>
                      SEO
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label="SLUG"
                        variant="outlined"
                        refs="slug"
                        value={lesson.slug}
                        fullWidth
                        helperText={state.errors.slug}
                        name="slug"
                        onChange={handleChange}
                        style={{ marginBottom: 20 }}
                      />
                      <PageviewIcon
                        style={{
                          height: 50,
                          width: 50,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        color="primary"
                        onClick={() =>
                          window
                            .open(
                              `https://fundamentos.me${lesson.slug}`,
                              "_blank"
                            )
                            .focus()
                        }
                      />
                    </div>
                    <TextField
                      label="Meta Title"
                      variant="outlined"
                      refs="metaTitle"
                      value={lesson.metaTitle}
                      fullWidth
                      helperText={state.errors.metaTitle}
                      name="metaTitle"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      label="Meta Description"
                      variant="outlined"
                      refs="metaDescription"
                      value={lesson.metaDescription}
                      fullWidth
                      helperText={state.errors.metaDescription}
                      name="metaDescription"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <PodcastFormFields
                      lesson={lesson}
                      handleChange={handleChange}
                    />
                    <PodcastShortFormFields
                      lesson={lesson}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center"
                      }}
                    >
                      {!lesson.imgThumb && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Imagem Thumb
                          </Text>
                          {state.imgFileLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["image/*"]}
                            dropzoneText={
                              "Arraste e solte a imagem ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({ name: "imgThumb", loadedFile })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            onDrop={() =>
                              setState({ ...state, imgFileLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lesson.imgThumb && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Imagem Thumb
                          </Text>
                          <Image
                            alt={lesson.name}
                            src={lesson.imgThumb}
                            style={{ width: "100%" }}
                          />
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, imgThumb: null })
                            }
                          >
                            Remover Imagem
                          </Button>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      {!lesson.urlVideoS3 && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Vídeo Completo
                          </Text>
                          {state.videoFileLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["video/*"]}
                            dropzoneText={
                              "Arraste e solte o vídeo completo ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({
                                name: "urlVideoS3",
                                loadedFile,
                              })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            maxFileSize={1000000000}
                            onDrop={() =>
                              setState({ ...state, videoFileLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lesson.urlVideoS3 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Vídeo Completo
                          </Text>
                          <iframe
                            src={lesson.urlVideoS3}
                            width="100%"
                            title={lesson.name}
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen;"
                          ></iframe>
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, urlVideoS3: null })
                            }
                          >
                            Remover Vídeo
                          </Button>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      {!lesson.urlShortVideoS3 && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Vídeo Curto
                          </Text>
                          {state.shortVideoFileLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["video/*"]}
                            dropzoneText={
                              "Arraste e solte o vídeo curto ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({
                                name: "urlShortVideoS3",
                                loadedFile,
                              })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            maxFileSize={1000000000}
                            onDrop={() =>
                              setState({
                                ...state,
                                shortVideoFileLoading: true,
                              })
                            }
                          />
                        </div>
                      )}
                      {lesson.urlShortVideoS3 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Vídeo Curto
                          </Text>
                          <iframe
                            src={lesson.urlShortVideoS3}
                            width="100%"
                            title={lesson.name}
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen;"
                          ></iframe>
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, urlShortVideoS3: null })
                            }
                          >
                            Remover Vídeo
                          </Button>
                        </div>
                      )}
                    </div>
                    <Text style={{ position: "relative", fontSize: 15, fontWeight: "bold", marginBottom: 10 }}>
                      Conteúdo Transcrito
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      {!lesson.printVersion_pt && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Lição Transcrita
                          </Text>
                          {state.printVersionLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["application/pdf"]}
                            dropzoneText={
                              "Arraste e solte o documento ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({ name: "printVersion_pt", loadedFile })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            onDrop={() =>
                              setState({ ...state, printVersionLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lesson.printVersion_pt && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Versão impressão em português
                          </Text>
                          <iframe
                            src={lesson.printVersion_pt}
                            width="100%"
                            title={lesson.name}
                          ></iframe>
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, printVersion_pt: null })
                            }
                          >
                            Remover Documento
                          </Button>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {!lesson.printVersion_en && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Lição Transcrita em inglês
                          </Text>
                          {state.printVersionLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["application/pdf"]}
                            dropzoneText={
                              "Arraste e solte o documento ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({ name: "printVersion_en", loadedFile })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            onDrop={() =>
                              setState({ ...state, printVersionLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lesson.printVersion_en && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Versão impressão em inglês
                          </Text>
                          <iframe
                            src={lesson.printVersion_en}
                            width="100%"
                            title={lesson.name}
                          ></iframe>
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, printVersion_en: null })
                            }
                          >
                            Remover Documento
                          </Button>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      {!lesson.printVersion_es && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Lição Transcrita em espanhol
                          </Text>
                          {state.printVersionLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["application/pdf"]}
                            dropzoneText={
                              "Arraste e solte o documento ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({ name: "printVersion_es", loadedFile })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            onDrop={() =>
                              setState({ ...state, printVersionLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lesson.printVersion_es && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Versão impressão em espanhol
                          </Text>
                          <iframe
                            src={lesson.printVersion_es}
                            width="100%"
                            title={lesson.name}
                          ></iframe>
                          <Button
                            style={{ marginTop: 20 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setLesson({ ...lesson, printVersion_es: null })
                            }
                          >
                            Remover Documento
                          </Button>
                        </div>
                      )}
                    </div>
                    <Select
                      label="Visibilidade"
                      id="visibilidade"
                      fullWidth
                      name="private"
                      handleChange={handleChange}
                      value={lesson.private}
                    >
                      <MenuItem value={true}>Privado</MenuItem>
                      <MenuItem value={false}>Público</MenuItem>
                    </Select>
                    <Select
                      label="Ativo no aplicativo"
                      id="active"
                      fullWidth
                      name="active"
                      handleChange={handleChange}
                      value={lesson.active}
                    >
                      <MenuItem value={true}>Ativo</MenuItem>
                      <MenuItem value={false}>Inativo</MenuItem>
                    </Select>
                    <Select
                      label="Idioma do Vídeo"
                      id="language"
                      fullWidth
                      name="language"
                      handleChange={handleChange}
                      value={lesson.language}
                    >
                      <MenuItem value="pt-BR">Português</MenuItem>
                      <MenuItem value="es">Espanhol</MenuItem>
                      <MenuItem value="en">Inglês</MenuItem>
                    </Select>
                    <Select
                      label="Canal do Youtube"
                      id="youtubeChannel"
                      fullWidth
                      name="youtubeChannel"
                      handleChange={handleChange}
                      value={lesson.youtubeChannel}
                    >
                      <MenuItem value="Fundamentos">Fundamentos</MenuItem>
                      <MenuItem value="Podcast Fundamentos">
                        Podcast Fundamentos
                      </MenuItem>
                    </Select>
                    <Select
                      label="Ministrado por"
                      id="taughtBy"
                      fullWidth
                      name="taughtBy"
                      handleChange={handleChange}
                      value={lesson.taughtBy}
                    >
                      {users?.map(user => <MenuItem key={`user${user.id}`} value={user.id}>{user.name}</MenuItem>)}
                    </Select>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleCancelButton}
                style={{ marginRight: 20 }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSaveButton}
              >
                Salvar
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {state.showConfirmDialog && (
        <ConfirmDialog
          open={state.showConfirmDialog}
          title="Lições"
          question={`Deseja realmente excluir a lição '${state.title}'?`}
          handleConfirm={handleDeleteButton}
          handleClose={() => setState({ ...state, showConfirmDialog: false })}
          cancelButton
        />
      )}
    </>
  );
}
