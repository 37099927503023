import { TextField as MUITextField, InputAdornment, IconButton } from '@material-ui/core'
import { Language as LanguageIcon } from '@material-ui/icons/';

export const TextField = (props) => {

    const {translateButton = false} = props

    return (<MUITextField
        variant='outlined'
        InputProps={translateButton ? {
            endAdornment: (
                <InputAdornment position="start">
                    <IconButton onClick={()=>console.log('icon')}>
                        <LanguageIcon color="primary" />
                    </IconButton>
                </InputAdornment>
            ),
        } : {}}
        {...props}
    />)
}

