import React, {useContext} from "react";
import { Switch, Route } from 'react-router-dom'
import Login from "../../components/pages/login/Login";
import RequestNewPasswordForm from "../../components/pages/forgotPassword/RequestNewPasswordForm";
import ResetPasswordForm from "../../components/pages/forgotPassword/ResetPasswordForm";
import Application from "./Application";
import { PrivateRoute } from '../../components/core/PrivateRoute';
import { AuthContext } from '../../contexts/AuthContext'
import { Loading } from "../core";



function Admin() {
  const { loadingCurrentUser } = useContext(AuthContext)

  return (
    <>
      { loadingCurrentUser ?
        <Loading />
        :
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>

          <Route exact path="/forgot-password/new">
            <RequestNewPasswordForm />
          </Route>

          <Route exact path="/forgot-password/:id">
            <ResetPasswordForm />
          </Route>

          <PrivateRoute path="/app">
            <Application />
          </PrivateRoute>
        </Switch>
      }
    </>
  )
}

export default Admin
