import { Grid, Text } from '../core'

export const LineDetails = ({ title, text }) => {
    return (
            <Grid container style={styles.container}>
                <Grid item xs={4}>
                    <Text>{title}</Text>
                </Grid>
                <Grid item xs={8}>
                    <Text>{text}</Text>
                </Grid>
            </Grid>
    )
}

const styles = {
    container: {
        borderTopWidth: 1,
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
    }
}