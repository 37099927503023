import { Image, Div, Text } from "../../core";
import { theme } from '../../../theme'

export const ContentImage = ({ content }) => {

    const { value = {} } = content
    const { url, caption } = value

    return (
        <>
            {url && <Image style={styles.img} src={url} />}
            {caption && <Text style={styles.caption}>{caption}</Text>}
            {!url && !caption && <Div style={styles.placeholder}><Text>Insira aqui sua Imagem</Text></Div>}
        </>
    );
};

const styles = {
    caption: {
        paddingLeft: 20,
        paddingRight: 20,
        alignSelf: 'flex-start',
        fontSize: 10
    },
    img: {
        marginTop: 20,
        maxWidth: 300,
        maxHeight: 200
    },
    placeholder: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderRadius: 10,
        padding: 10
    }
}