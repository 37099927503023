import { withStyles } from '@material-ui/core/styles';
import { Slider as MUISlider } from '@material-ui/core'

/* Props
    languageTheme (String) default 'pt' | ['pt', 'es'] - Change Object Theme
*/
const CustomSlider = withStyles((theme) => ({
    root: {
        color: ({ languageTheme = 'pt' }) => languageTheme === 'pt' ? theme.palette.primary.main : theme.palette.primaryES.main,
    }
}))(MUISlider);

export const Slider = (props) => <CustomSlider {...props} />
